export const SET_FIRST_SECOND = "SET_FIRST_SECOND";

export const SET_SELECTED_RECORD_ID = "SET_SELECTED_RECORD_ID";
export const SET_SELECTED_SUBRECORD_ID = "SET_SELECTED_SUBRECORD_ID";
export const FETCH_CURRENT_VERSIONS = "FETCH_CURRENT_VERSIONS";
export const FETCH_SOMURA_START = "FETCH_SOMURA_START";

export const AUTH_AUTHENTICATING = "AUTH_AUTHENTICATING";
export const AUTH_AUTHENTICATED = "AUTH_AUTHENTICATED";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGON = "AUTH_LOGON";
export const AUTH_LOGON_ERROR = "AUTH_LOGON_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const DISABLE_LOCATION_SELECTION = "DISABLE_LOCATION_SELECTION";
export const DISABLE_LOCATIONS_ALL_SELECTION = "DISABLE_LOCATIONS_ALL_SELECTION";

export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const COMPANIES_FAILURE = "COMPANIES_FAILURE";
export const SAVE_COMPANIES = "SAVE_COMPANIES";
export const DELETE_COMPANIES = "DELETE_COMPANIES";

export const FETCH_INDUSTRIES_SUCCESS = "FETCH_INDUSTRIES_SUCCESS";
export const INDUSTRIES_FAILURE = "INDUSTRIES_FAILURE";
export const SET_INDUSTRY_ID = "SET_INDUSTRY_ID";
export const SAVE_INDUSTRY = "SAVE_INDUSTRY";
export const DELETE_INDUSTRY = "DELETE_INDUSTRY";

export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const ADD_REGION = "ADD_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";
export const SET_COUNTRY_ID = "SET_COUNTRY_ID";

export const INIT_DATABASE = "INIT_DATABASE";

export const FETCH_CURRENT_STATS = "FETCH_CURRENT_STATS";

export const FETCH_TODAY_STATE = "FETCH_TODAY_STATE";

export const CHECK_MAIL = "CHECK_MAIL";
export const CHECK_CREDENTIALS = "CHECK_CREDENTIALS";
export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const SET_LOGIN_NAME = "SET_LOGIN_NAME";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
export const SET_PROGRESS_VALUE = "SET_PROGRESS_VALUE";

export const FETCH_GLOBAL_SETTINGS_SUCCESS = "FETCH_GLOBAL_SETTINGS_SUCCESS";
export const SET_GLOBAL_SETTING = "SET_GLOBAL_SETTING";

export const FETCH_PERSONAL_SETTINGS_SUCCESS =
  "FETCH_PERSONAL_SETTINGS_SUCCESS";
export const SET_PERSONAL_SETTING = "SET_PERSONAL_SETTING";
export const CREATE_PERSONAL_SETTINGS = "CREATE_PERSONAL_SETTINGS";
export const FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS =
  "FETCH_PERSONAL_DISPLAYSETTINGS_SUCCESS";
export const SET_PERSONAL_DISPLAYSETTINGS = "SET_PERSONAL_DISPLAYSETTINGS";
export const CREATE_PERSONAL_DISPLAYSETTINGS =
  "CREATE_PERSONAL_DISPLAYSETTINGS";
export const FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS =
  "FETCH_STANDARD_DISPLAYSETTINGS_SUCCESS";

export const DATA_FAILURE = "DATA_FAILURE";
export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_RESET = "DATA_RESET";
export const ACTION_SUCCESS = "ACTION_SUCCESS";

export const FETCH_REMINDERS_SUCCESS = "FETCH_REMINDERS_SUCCESS";
export const ADD_REMINDER = "ADD_REMINDER";
export const UPDATE_REMINDER = "UPDATE_REMINDER";
export const DELETE_REMINDER = "DELETE_REMINDER";
export const REMINDERS_FAILURE = "REMINDERS_FAILURE";
export const SET_SELECTED_REMINDER = "SET_SELECTED_REMINDER";
export const SET_DISPLAY_REMINDER = "SET_DISPLAY_REMINDER";
export const SET_REMINDER_ALERT = "SET_REMINDER_ALERT";
export const SET_REMINDER_ALERT_TIME = "SET_REMINDER_ALERT_TIME";
export const OPEN_REMINDER_DIALOG = "OPEN_REMINDER_DIALOG";

export const FETCH_REMINDERTIMES_SUCCESS = "FETCH_REMINDERTIMES_SUCCESS";

export const FETCH_REMINDER_CATEGORIES_SUCCESS =
  "FETCH_REMINDER_CATEGORIES_SUCCESS";
export const ADD_REMINDER_CATEGORY = "ADD_REMINDER_CATEGORY";
export const UPDATE_REMINDER_CATEGORY = "UPDATE_REMINDER_CATEGORY";
export const DELETE_REMINDER_CATEGORY = "DELETE_REMINDER_CATEGORY";
export const SET_SELECTED_REMINDER_CATEGORY = "SET_SELECTED_REMINDER_CATEGORY";

export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_DISPLAY_YEAR = "SET_DISPLAY_YEAR";
export const SET_DISPLAY_ABSENCE_TYPE = "SET_DISPLAY_ABSENCE_TYPE";
export const SET_SYSTEM_LANGUAGE = "SET_SYSTEM_LANGUAGE";
export const SHOW_CONTEXT_MENU = "SHOW_CONTEXT_MENU";

export const SHOW_MESSAGE = "SHOW_MESSAGE";

export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const SET_LOGGED_USER = "SET_LOGGED_USER";

export const FETCH_OFFDAYS_SUCCESS = "FETCH_OFFDAYS_SUCCESS";
export const SET_SELECTED_WORKDAYS = "SET_SELECTED_WORKDAYS";
export const SET_SELECTED_CALENDARDAYS = "SET_SELECTED_CALENDARDAYS";
export const SET_SELECTED_DAYS = "SET_SELECTED_DAYS";
export const SET_SELECTED_DATES = "SET_SELECTED_DATES";
export const SELECT_SELECTED_DATE = "SELECT_SELECTED_DATE";
export const SELECT_SELECTED_DATES = "SELECT_SELECTED_DATES";
export const SET_SELECTED_IDS = "SET_SELECTED_IDS";

export const FETCH_YEAR_SUCCESS = "FETCH_YEAR_SUCCESS";
export const FETCH_YEAR_FAILURE = "FETCH_YEAR_FAILURE";
export const FETCH_YEAR_STATIC_SUCCESS = "FETCH_YEAR_STATIC_SUCCESS";
export const FETCH_YEAR_COUNT_SUCCESS = "FETCH_YEAR_COUNT_SUCCESS";
export const FETCH_YEAR_USER_SUCCESS = "FETCH_YEAR_USER_SUCCESS";
export const FETCH_YEAR_TYPES_SUCCESS = "FETCH_YEAR_TYPES_SUCCESS";
export const SET_DISPLAY_USER = "SET_DISPLAY_USER";
export const SET_DISPLAY_APPROVEMENT = "SET_DISPLAY_APPROVEMENT";
export const SET_DISPLAY_DATE = "SET_DISPLAY_DATE";
export const SET_YEAR_WORKDAYS = "SET_YEAR_WORKDAYS";

export const FETCH_MONTH_FAILURE = "FETCH_MONTH_FAILURE";
export const SET_DISPLAY_DEPARTMENT = "SET_DISPLAY_DEPARTMENT";
export const FETCH_MONTH_DATA_SUCCESS = "FETCH_MONTH_DATA_SUCCESS";
export const FETCH_MONTH_STATIC_DATA_SUCCESS =
  "FETCH_MONTH_STATIC_DATA_SUCCESS";
export const FETCH_MONTH_USER_DATA_SUCCESS = "FETCH_MONTH_USER_DATA_SUCCESS";
export const FETCH_MONTH_SUM_DATA_SUCCESS = "FETCH_MONTH_SUM_DATA_SUCCESS";
export const SET_DAY_INFO = "SET_DAY_INFO";

export const FETCH_STATS_SUCCESS = "FETCH_STATS_SUCCESS";
export const FETCH_STATS_FAILURE = "FETCH_STATS_FAILURE";
export const FETCH_ABSENCE_STATS_SUCCESS = "FETCH_ABSENCE_STATS_SUCCESS";

export const FETCH_PERSON_SUCCESS = "FETCH_PERSON_SUCCESS";
export const FETCH_PERSONS_MIN_SUCCESS = "FETCH_PERSONS_MIN_SUCCESS";
export const UPDATE_PERSON = "UPDATE_PERSON";
export const ADD_PERSON = "ADD_PERSON";
export const ADD_PERSON_ASSET = "ADD_PERSON_ASSET";
export const UPDATE_PERSON_ASSET = "UPDATE_PERSON_ASSET";
export const PERSONS_FAILURE = "PERSONS_FAILURE";
export const SET_PERSON_ID = "SET_PERSON_ID";
export const ADD_DISPLAY_SETTINGS = "ADD_DISPLAY_SETTINGS";
export const FETCH_DISPLAY_SETTINGS_SUCCESS = "FETCH_DISPLAY_SETTINGS_SUCCESS";
export const FETCH_DISPLAY_SETTINGS_FAILURE = "FETCH_DISPLAY_SETTINGS_FAILURE";
export const ADD_PERSON_TIMEPLAN = "ADD_PERSON_TIMEPLAN";
export const UPDATE_PERSON_TIMEPLAN = "UPDATE_PERSON_TIMEPLAN";
export const DELETE_PERSON_TIMEPLAN = "DELETE_PERSON_TIMEPLAN";

export const FETCH_ENTITLEMENTS_SUCCESS = "FETCH_ENTITLEMENTS_SUCCESS";
export const FETCH_ENTITLEMENT = "FETCH_ENTITLEMENT";
export const SET_ENTITLEMENT_ID = "SET_ENTITLEMENT_ID";
export const PERSON_ENTITLEMENTS_FAILURE = "PERSON_ENTITLEMENTS_FAILURE";
export const UPDATE_PERSON_ENTITLEMENT = "UPDATE_PERSON_ENTITLEMENT";
export const ADD_PERSON_ENTITLEMENT = "ADD_PERSON_ENTITLEMENT";
export const DELETE_PERSON_ENTITLEMENT = "DELETE_PERSON_ENTITLEMENT";
export const RESET_ENTITLEMENT = "RESET_ENTITLEMENT";

export const FETCH_WAGES_SUCCESS = "FETCH_WAGES_SUCCESS";
export const WAGES_FAILURE = "WAGES_FAILURE";
export const SET_SALARY_ID = "SET_SALARY_ID";
export const ADD_SALARY = "ADD_SALARY";
export const UPDATE_SALARY = "UPDATE_SALARY";
export const DELETE_SALARY = "DELETE_SALARY";

export const FETCH_COVSTATES_SUCCESS = "FETCH_COVSTATES_SUCCESS";
export const ADD_COVSTATE = "ADD_COVSTATE";
export const UPDATE_COVSTATE = "UPDATE_COVSTATE";
export const SET_COVSTATE_ID = "SET_COVSTATE_ID";

export const FETCH_OFFDAYTYPES_SUCCESS = "FETCH_OFFDAYTYPES_SUCCESS";
export const OFFDAYTYPES_FAILURE = "FETCH_OFFDAYTYPES_FAILURE";
export const UPDATE_OFFDAYTYPE = "UPDATE_OFFDAYTYPE";
export const ADD_OFFDAYTYPE = "ADD_OFFDAYTYPE";
export const UPDATE_STANDARD_DISPLAYSETTINGS =
  "UPDATE_STANDARD_DISPLAYSETTINGS";
export const DELETE_OFFDAYTYPE = "DELETE_OFFDAYTYPE";
export const SET_OFFDAYTYPE_ID = "SET_OFFDAYTYPE_ID";
export const SAVE_OFFDAYTYPENAME = "SAVE_OFFDAYTYPENAME";
export const SET_APPROVEMENT_STATE = "SET_APPROVEMENT_STATE";

export const UPDATE_OFFDAYTEXT = "UPDATE_OFFDAYTEXT";
export const ADD_OFFDAYTEXT = "ADD_OFFDAYTEXT";
export const DELETE_OFFDAYTEXT = "DELETE_OFFDAYTEXT";

export const FETCH_OFFDAYREASON = "FETCH_OFFDAYREASON";
export const UPDATE_OFFDAYREASON = "UPDATE_OFFDAYREASON";
export const ADD_OFFDAYREASON = "ADD_OFFDAYREASON";
export const DELETE_OFFDAYREASON = "DELETE_OFFDAYREASON";

export const FETCH_UNPUBLISHED_DAYS_SUCCESS = "FETCH_UNPUBLISHED_DAYS_SUCCESS";
export const FETCH_UNPUBLISHED_DAYS_FAILURE = "FETCH_UNPUBLISHED_DAYS_FAILURE";

export const FETCH_SINGLE_MESSAGE_SUCCESS = "FETCH_SINGLE_MESSAGE_SUCCESS";
export const CLEAR_SINGLE_MESSAGE = "CLEAR_SINGLE_MESSAGE";
export const FETCH_RECEIVED_MESSAGES_SUCCESS =
  "FETCH_RECEIVED_MESSAGES_SUCCESS";
export const FETCH_SENT_MESSAGES_SUCCESS = "FETCH_SENT_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "FETCH_MESSAGES_FAILURE";
export const MOVE_MESSAGE_TO_RECIPIENTFOLDER =
  "MOVE_MESSAGE_TO_RECIPIENTFOLDER";
export const MOVE_MESSAGE_TO_SENDERFOLDER = "MOVE_MESSAGE_TO_SENDERFOLDER";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ANSWER_MESSAGE = "ANSWER_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const SAVE_MESSAGE = "SAVE_MESSAGE";
export const SAVE_MESSAGE_RECIPIENTS = "SAVE_MESSAGE_RECIPIENTS";
export const SAVE_DRAFT_MESSAGE = "SAVE_DRAFT_MESSAGE";
export const UPDATE_DRAFT_MESSAGE = "UPDATE_DRAFT_MESSAGE";
export const DELETE_DRAFT_MESSAGE = "DELETE_DRAFT_MESSAGE";
export const UPDATE_DRAFT_RECIPIENTS = "UPDATE_DRAFT_RECIPIENTS";

export const FETCH_MESSAGEFOLDERS_SUCCESS = "FETCH_MESSAGEFOLDERS_SUCCESS";
export const FETCH_MESSAGEFOLDERS_FAILURE = "FETCH_MESSAGEFOLDERS_FAILURE";
export const DELETE_MESSAGEFOLDER = "DELETE_MESSAGEFOLDER";
export const EMPTY_MESSAGEFOLDER = "EMPTY_MESSAGEFOLDER";
export const EMPTY_SENDERFOLDER = "EMPTY_SENDERFOLDER";
export const EMPTY_RECYCLEBIN = "EMPTY_RECYCLEBIN";
export const ADD_MESSAGEFOLDER = "ADD_MESSAGEFOLDER";
export const RENAME_MESSAGEFOLDER = "RENAME_MESSAGEFOLDER";

export const SET_SELECTED_FOLDER_ID = "SET_SELECTED_FOLDER_ID";
export const SET_SELECTED_MESSAGE_ID = "SET_SELECTED_MESSAGE_ID";
export const SET_MESSAGE_READ = "SET_MESSAGE_READ";
export const SET_MESSAGE_UNREAD = "SET_MESSAGE_UNREAD";
export const SET_MESSAGE_ACCESS = "SET_MESSAGE_ACCESS";
export const SET_MESSAGE_PASSWORD = "SET_MESSAGE_PASSWORD";
export const SET_MESSAGE_SENDABLE = "SET_MESSAGE_SENDABLE";
export const SET_MESSAGE_SENT = "SET_MESSAGE_SENT";
export const COPY_MESSAGE_TO_DRAFT = "COPY_MESSAGE_TO_DRAFT";
export const COPY_DRAFT_TO_MESSAGE = "COPY_DRAFT_TO_MESSAGE";

export const ADD_MESSAGE_IDS = "ADD_MESSAGE_IDS";
export const SET_FIRST_MESSAGE_ID = "SET_FIRST_MESSAGE_ID";
export const REMOVE_MESSAGE_ID = "REMOVE_MESSAGE_ID";
export const SELECT_MESSAGE_ID = "SELECT_MESSAGE_ID";
export const UNSELECT_MESSAGE_ID = "UNSELECT_MESSAGE_ID";
export const CLEAR_MESSAGE_IDS = "CLEAR_MESSAGE_IDS";
export const SELECT_ALL_MESSAGE_IDS = "SELECT_ALL_MESSAGE_IDS";
export const UNSELECT_ALL_MESSAGE_IDS = "UNSELECT_ALL_MESSAGE_IDS";

export const FETCH_COMPANY_LOCATIONS_SUCCESS =
  "FETCH_COMPANY_LOCATIONS_SUCCESS";
export const ADD_COMPANY_LOCATION = "ADD_COMPANY_LOCATION";
export const UPDATE_COMPANY_LOCATION = "UPDATE_COMPANY_LOCATION";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const COMPANY_FAILURE = "COMPANY_FAILURE";
export const SET_SELECTED_LOCATION_ID = "SET_SELECTED_LOCATION_ID";

export const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
export const FETCH_DEPARTMENTS_MINIMAL_SUCCESS =
  "FETCH_DEPARTMENTS_MINIMAL_SUCCESS";
export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DEPARTMENTS_FAILURE = "DEPARTMENTS_FAILURE";
export const RESET_DEPARTMENT = "RESET_DEPARTMENT";
export const NEW_DEPARTMENT = "NEW_DEPARTMENT";
export const SET_DEPARTMENT_ID = "SET_DEPARTMENT_ID";

export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLETYPES_SUCCESS = "FETCH_ARTICLETYPES_SUCCESS";
export const FETCH_ARTICLEMODELS_SUCCESS = "FETCH_ARTICLEMODELS_SUCCESS";
export const FETCH_MOVEMENTTYPES_SUCCESS = "FETCH_MOVEMENTTYPES_SUCCESS";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_REASONS_SUCCESS = "FETCH_REASONS_SUCCESS";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const ARTICLES_FAILURE = "ARTICLES_FAILURE";
export const SET_ARTICLE_ID = "SET_ARTICLE_ID";
export const SET_ARTICLETYPE_ID = "SET_ARTICLETYPE_ID";
export const SET_ARTICLEMODEL_ID = "SET_ARTICLEMODEL_ID";
export const SET_MOVEMENTTYPE_ID = "SET_MOVEMENTTYPE_ID";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const SET_REASON_ID = "SET_REASON_ID";
export const SET_STATE_ID = "SET_STATE_ID";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const CREATE_ARTICLEMODEL = "CREATE_ARTICLEMODEL";
export const CREATE_ARTICLETYPE = "CREATE_ARTICLETYPE";
export const CREATE_MOVEMENTTYPE = "CREATE_MOVEMENTTYPE";
export const CREATE_MOVEMENT = "CREATE_MOVEMENT";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_REASON = "CREATE_REASON";
export const CREATE_STATE = "CREATE_STATE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLEMODEL = "UPDATE_ARTICLEMODEL";
export const UPDATE_ARTICLETYPE = "UPDATE_ARTICLETYPE";
export const UPDATE_MOVEMENTTYPE = "UPDATE_MOVEMENTTYPE";
export const UPDATE_MOVEMENT = "UPDATE_MOVEMENT";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_REASON = "UPDATE_REASON";
export const UPDATE_STATE = "UPDATE_STATE";
export const DELETE_ARTICLEMODEL = "DELETE_ARTICLEMODEL";
export const DELETE_ARTICLETYPE = "DELETE_ARTICLETYPE";
export const DELETE_MOVEMENT = "DELETE_MOVEMENT";

export const FETCH_GROUPTYPES_SUCCESS = "FETCH_GROUPTYPES_SUCCESS";
export const GROUPTYPES_FAILURE = "GROUPTYPES_FAILURE";

export const FETCH_CALENDARSETTINGS_SUCCESS = "FETCH_CALENDARSETTINGS_SUCCESS";
export const FETCH_CALENDARSETTINGS_FAILURE = "FETCH_CALENDARSETTINGS_FAILURE";
export const UPDATE_CALENDARSETTINGS = "UPDATE_CALENDARSETTINGS";
export const UPDATE_DISPLAYSETTING = "UPDATE_DISPLAYSETTING";
export const SET_DISPLAYSETTING_ID = "SET_DISPLAYSETTING_ID";

export const FETCH_SPECIALDAYS_SUCCESS = "FETCH_SPECIALDAYS_SUCCESS";
export const SET_SPECIALDAY_ID = "SET_SPECIALDAY_ID";
export const ADD_SPECIALDAY = "ADD_SPECIALDAY";
export const UPDATE_SPECIALDAY = "UPDATE_SPECIALDAY";
export const DELETE_SPECIALDAY = "DELETE_SPECIALDAY";
export const SPECIALDAYS_FAILURE = "SPECIALDAYS_FAILURE";

export const FETCH_REMARKABLEDAYS_SUCCESS = "FETCH_REMARKABLEDAYS_SUCCESS";
export const SET_REMARKABLEDAY_ID = "SET_REMARKABLEDAY_ID";
export const UPDATE_REMARKABLEDAY = "UPDATE_REMARKABLEDAY";
export const REMARKABLEDAYS_FAILURE = "REMARKABLEDAYS_FAILURE";

export const FETCH_HOLIDAYS_SUCCESS = "FETCH_HOLIDAYS_SUCCESS";
export const SET_HOLIDAY_ID = "SET_HOLIDAY_ID";
export const SET_HOLIDAYTYPE_ID = "SET_HOLIDAYTYPE_ID";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const FETCH_HOLIDAYTYPES_SUCCESS = "FETCH_HOLIDAYTYPES_SUCCESS";
export const HOLIDAYS_FAILURE = "HOLIDAYS_FAILURE";
export const FETCH_DAYTYPES_SUCCESS = "FETCH_DAYTYPES_SUCCESS";
export const SET_DAYTYPE_ID = "SET_DAYTYPE_ID";
export const CREATE_DAYTYPE = "CREATE_DAYTYPE";
export const UPDATE_DAYTYPE = "UPDATE_DAYTYPE";
export const DELETE_DAYTYPE = "DELETE_DAYTYPE";

export const SET_OFFDAYS_SUCCESS = "SET_OFFDAYS_SUCCESS";
export const DELETE_OFFDAYS = "DELETE_OFFDAYS";
export const REQUEST_OFFDAYS = "REQUEST_OFFDAYS";
export const PUBLISH_OFFDAYS = "PUBLISH_OFFDAYS";
export const ACCEPT_OFFDAYS = "ACCEPT_OFFDAYS";
export const DECLINE_OFFDAYS = "DECLINE_OFFDAYS";
export const SET_OFFDAYS_FAILURE = "SET_OFFDAYS_FAILURE";

export const FETCH_REMARKS_SUCCESS = "FETCH_REMARKS_SUCCESS";
export const ADD_REMARK = "ADD_REMARK";
export const UPDATE_REMARK = "UPDATE_REMARK";
export const DELETE_REMARK = "DELETE_REMARK";
export const SET_REMARK_ID = "SET_REMARK_ID";
export const SET_DISPLAY_REMARKS_IDS = "SET_DISPLAY_REMARKS_IDS";

/* Time Tracking */
export const FETCH_TIMES_SUCCESS = "FETCH_TIMES_SUCCESS";
export const FETCH_LAST_TIMES_SUCCESS = "FETCH_LAST_TIMES_SUCCESS";
export const FETCH_USERTIMES_SUCCESS = "FETCH_USERTIMES_SUCCESS";
export const FETCH_USERTIMES_CUM_SUCCESS = "FETCH_USERTIMES_CUM_SUCCESS";
export const FETCH_TIMES_FAULTY_SUCCESS = "FETCH_TIMES_FAULTY_SUCCESS";
export const FETCH_USER_MONTH_TIMES_SUCCESS = "FETCH_USER_MONTH_TIMES_SUCCESS";
export const FETCH_ALIVE_TIME_SUCCESS = "FETCH_ALIVE_TIME_SUCCESS";
export const FETCH_USER_TIME_STATE = "FETCH_USER_TIME_STATE";
export const SET_WEB_TIME = "SET_WEB_TIME";
export const SET_TIME = "SET_TIME";
export const END_TIME = "END_TIME";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const SET_SELECTED_TIME = "SET_SELECTED_TIME";
export const SET_SELECTED_TIME_FORM_ID = "SET_SELECTED_TIME_FORM_ID";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const OPEN_START_TIME = "OPEN_START_TIME";
export const OPEN_END_TIME = "OPEN_END_TIME";
export const UPDATE_TIME = "UPDATE_TIME";
export const DELETE_TIME = "DELETE_TIME";
export const ADD_TIME = "ADD_TIME";
export const TERMINATE_DAY_SUCCESS = "TERMINATE_DAY_SUCCESS";
export const TERMINATE_DAY_USER_SUCCESS = "TERMINATE_DAY_USER_SUCCESS";
export const TERMINATE_MONTH_SUCCESS = "TERMINATE_MONTH_SUCCESS";
export const OPEN_TERMINATION = "OPEN_TERMINATION";
export const FETCH_MONTH_TERMINATED = "FETCH_MONTH_TERMINATED";
export const FETCH_NOT_TERMINATED = "FETCH_NOT_TERMINATED";
export const FETCH_TIME_SUMS = "FETCH_TIME_SUMS";
export const FETCH_TIME_STATE = "FETCH_TIME_STATE";

export const FETCH_RECURRING_ABSENCES_SUCCESS =
  "FETCH_RECURRING_ABSENCES_SUCCESS";
export const CREATE_RECURRING_ABSENCE = "CREATE_RECURRING_ABSENCE";
export const UPDATE_RECURRING_ABSENCE = "UPDATE_RECURRING_ABSENCE";
export const DELETE_RECURRING_ABSENCE = "DELETE_RECURRING_ABSENCE";
export const SET_RECURRING_ABSENCE_ID = "SET_RECURRING_ABSENCE_ID";
export const SPLIT_RECURRING_ABSENCE_SUCCESS =
  "SPLIT_RECURRING_ABSENCE_SUCCESS";

export const FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS =
  "FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS";
export const CREATE_EXCLUDED_RECURRING_ABSENCE =
  "CREATE_EXCLUDED_RECURRING_ABSENCE";
export const DELETE_EXCLUDED_RECURRING_ABSENCES =
  "DELETE_EXCLUDED_RECURRING_ABSENCES";

export const FETCH_DAILY_TIME_PLANS_SUCCESS = "FETCH_DAILY_TIME_PLANS_SUCCESS";
export const CREATE_DAILY_TIME_PLAN = "CREATE_DAILY_TIME_PLAN";
export const UPDATE_DAILY_TIME_PLAN = "UPDATE_DAILY_TIME_PLAN";
export const DELETE_DAILY_TIME_PLAN = "DELETE_DAILY_TIME_PLAN";
export const SET_DAILY_TIME_PLAN_ID = "SET_DAILY_TIME_PLAN_ID";
export const SET_TIMEPLAN_ID = "SET_TIMEPLAN_ID";

export const FETCH_WEEKLY_TIME_PLANS_SUCCESS =
  "FETCH_WEEKLY_TIME_PLANS_SUCCESS";
export const CREATE_WEEKLY_TIME_PLAN = "CREATE_WEEKLY_TIME_PLAN";
export const UPDATE_WEEKLY_TIME_PLAN = "UPDATE_WEEKLY_TIME_PLAN";
export const DELETE_WEEKLY_TIME_PLAN = "DELETE_WEEKLY_TIME_PLAN";
export const SET_WEEKLY_TIME_PLAN_ID = "SET_WEEKLY_TIME_PLAN_ID";

export const FETCH_TIMECLOCKS_SUCCESS = "FETCH_TIMECLOCKS_SUCCESS";
export const FETCH_TIMECLOCKS_FAILURE = "FETCH_TIMECLOCKS_FAILURE";
export const SET_SELECTED_TIMECLOCK = "SET_SELECTED_TIMECLOCK";
export const CREATE_TIMECLOCK = "CREATE_TIMECLOCK";
export const UPDATE_TIMECLOCK = "UPDATE_TIMECLOCK";
export const DELETE_TIMECLOCK = "DELETE_TIMECLOCK";

export const READ_EXIT_REASONS = "READ_EXIT_REASONS";
export const CREATE_EXIT_REASON = "CREATE_EXIT_REASON";
export const UPDATE_EXIT_REASON = "UPDATE_EXIT_REASON";
export const DELETE_EXIT_REASON = "DELETE_EXIT_REASON";
export const SET_SELECTED_EXIT_REASON = "SET_SELECTED_EXIT_REASON";

export const FETCH_LOG_SUCCESS = "FETCH_LOG_SUCCESS";

export const FETCH_ROLLS_SUCCESS = "FETCH_ROLLS_SUCCESS";
export const CREATE_ROLL_SUCCESS = "CREATE_ROLL_SUCCESS";
export const UPDATE_ROLL_SUCCESS = "UPDATE_ROLL_SUCCESS";
export const DELETE_ROLL_SUCCESS = "DELETE_ROLL_SUCCESS";
export const COPY_RIGHTS_SUCCESS = "COPY_ROLL_SUCCESS";
export const SET_SELECTED_ROLL_ID = "SET_SELECTED_ROLL_ID";

export const FETCH_CHANGELOG_SUCCESS = "FETCH_CHANGELOG_SUCCESS";
export const CREATE_CHANGELOG_SUCCESS = "CREATE_CHANGELOG_SUCCESS";
export const UPDATE_CHANGELOG_SUCCESS = "UPDATE_CHANGELOG_SUCCESS";
export const DELETE_CHANGELOG_SUCCESS = "DELETE_CHANGELOG_SUCCESS";
export const SET_CHANGELOG_ID = "SET_CHANGELOG_ID";

export const SEND_MAIL = "SEND_MAIL";

export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const SET_PROJECT_ID = "SET_PROJECT_ID";

export const FETCH_DOCS_TITLES_SUCCESS = "FETCH_DOCS_TITLES_SUCCESS";
export const FETCH_DOC_SUCCESS = "FETCH_DOC_SUCCESS";
export const CREATE_DOC = "CREATE_DOC";
export const UPDATE_DOC = "UPDATE_DOC";
export const DELETE_DOC = "DELETE_DOC";
export const SET_DOC_ID = "SET_DOC_ID";
export const SET_DOC_SETTINGS = "SET_DOC_SETTINGS";

export const OPEN_USER_STATS = "OPEN_USER_STATS";
