var baseUrl = "//" + window.location.host + "/api/";
if (window.location.hostname === "localhost") {
  baseUrl = "https://niw-wrank-dev-01.netitwork.net/api/";
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

async function post(path, data) {
  return fetch(baseUrl + path, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(handleErrors)
    .then((response) => response.json());
}

async function post_image(path, data) {
  return fetch(baseUrl + path, {
    method: "POST",
    body: data,
  })
    .then(handleErrors)
    .then((response) => response.json());
}

async function loginPost(path, data) {
  return await fetch(baseUrl + path, {
    method: "POST",
    body: data || null,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then(handleErrors);
}

async function renew(path) {
  return await fetch(baseUrl + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then(handleErrors);
}

// Only for development purposes
export function utility() {
  return async () => {
    return await post("utilities/convert_insurances.php");
  };
}

// Authentication
export function login(user, pass) {
  return async () => {
    return await loginPost(
      "auth/login.php",
      `user=${encodeURIComponent(user)}&pass=${encodeURIComponent(pass)}`
    );
  };
}

export function renewToken() {
  return async () => {
    return await renew("/login");
  };
}

export function initDatabase() {
  return async () => {
    return await post("general/initDatabase.php");
  };
}

export function getCurrentStats(values) {
  return async () => {
    return await post("general/fetchCurrentState.php", values);
  };
}

export function getCurrentVersions() {
  return async () => {
    return await post("general/fetchCurrentVersions.php");
  };
}

export function getSomuraStart() {
  return async () => {
    return await post("general/fetchSomuraStart.php");
  };
}

export function todayState(values){
    return async () => {
      return await post("today/read_static.php", values);
    };
}

export function dashboard(id) {
  return async () => {
    return await post("dashboard/read.php", id);
  };
}

export function addLog(values) {
  return async () => {
    return await post("log/save.php", values);
  };
}

export function readLog(values) {
  return async () => {
    return await post("log/read.php", values);
  };
}

export function checkMail(mail) {
  return async () => {
    return await post("loggeduser/checkmail.php", mail);
  };
}

export function checkLogin(creds) {
  return async () => {
    return await post("loggeduser/checklogin.php", creds);
  };
}

export function loginUser(values) {
  return async () => {
    return await post("loggeduser/read.php", values);
  };
}

export function setLoggedInUser(id) {
  return async () => {
    return await post("loggeduser/getfromid.php", id);
  };
}

export function createAdmin(values) {
  return async () => {
    return await post("loggeduser/createAdmin.php", values);
  };
}

export function savePW(values) {
  return async () => {
    return await post("loggeduser/savePW.php", values);
  };
}

export function saveTempPW(values) {
  return async () => {
    return await post("loggeduser/saveTempPW.php", values);
  };
}

export function deletePW(values) {
  return async () => {
    return await post("loggeduser/deletePW.php", values);
  };
}

export function personalSettingsCreate(values) {
  return async () => {
    return await post("personalsettings/create.php", values);
  };
}

export function personalSettingsRead(values) {
  return async () => {
    return await post("personalsettings/read.php", values);
  };
}

export function personalSettingsSet(values) {
  return async () => {
    return await post("personalsettings/set.php", values);
  };
}

export function personalDisplaySettingsCreate(values) {
  return async () => {
    return await post("personaldisplaysettings/create.php", values);
  };
}

export function personalDisplaySettingsRead(values) {
  return async () => {
    return await post("personaldisplaysettings/read.php", values);
  };
}

export function personalDisplaySettingsSet(values) {
  return async () => {
    return await post("personaldisplaysettings/set.php", values);
  };
}

export function standardDisplaySettingsRead(values) {
  return async () => {
    return await post("personaldisplaysettings/read_standard.php", values);
  };
}

export function yearData(values) {
  return async () => {
    // return await post("year/read.php", values);
    return await post("year/read_user.php", values);
  };
}

export function yearStaticData(values) {
  return async () => {
    return await post("year/read_static.php", values);
  };
}

export function yearCountData(values) {
  return async () => {
    return await post("year/read_count.php", values);
  };
}

export function yearUserData(values) {
  return async () => {
    return await post("year/read_day.php", values);
  };
}

export function yearUserAbsenceTypes(values) {
  return async () => {
    return await post("year/read_absence_types.php", values);
  };
}

export function monthStaticData(values) {
  return async () => {
    return await post("month/read_static.php", values);
  };
}

export function monthSumData(values) {
  return async () => {
    return await post("month/read_sums.php", values);
  };
}

export function monthUserData(values) {
  return async () => {
    return await post("month/read_users.php", values);
  };
}

export function unpublishedDays(userId) {
  return async () => {
    return await post("unpublisheddays/read.php", userId);
  };
}

export function message_single(values) {
  return async () => {
    return await post("messages/read_single.php", values);
  };
}

export function messages_received(values) {
  return async () => {
    return await post("messages/read_received_folder_headers.php", values);
  };
}

export function messages_sent(values) {
  return async () => {
    return await post("messages/read_sent_folder_headers.php", values);
  };
}

export function messageFolders(userId) {
  return async () => {
    return await post("messagefolders/read.php", userId);
  };
}

export function setMessageRead(values) {
  return async () => {
    return await post("messages/mark_read.php", values);
  };
}

export function setMessageUnread(values) {
  return async () => {
    return await post("messages/mark_unread.php", values);
  };
}

export function setMessageAccess(values) {
  return async () => {
    return await post("messages/allow_access.php", values);
  };
}

export function setMessagePassword(values) {
  return async () => {
    return await post("messages/allow_new_password.php", values);
  };
}

export function insMessage(userId) {
  return async () => {
    return await post("messages/add.php", userId);
  };
}

export function reMessage(id) {
  return async () => {
    return await post("messages/answer.php", id);
  };
}

export function delMessage(id) {
  return async () => {
    return await post("messages/delete.php", id);
  };
}

export function messageSend(id) {
  return async () => {
    return await post("messages/send.php", id);
  };
}

export function messageSystemSend(values) {
  return async () => {
    return await post("messages/send_system.php", values);
  };
}

export function storeMessage(values) {
  return async () => {
    return await post("messages/save.php", values);
  };
}

export function storeMessageRecipients(values) {
  return async () => {
    return await post("messages/save_recipients.php", values);
  };
}

export function assignRecipientMessageFolder(values) {
  return async () => {
    return await post("messages/assign_recipientfolder.php", values);
  };
}

export function assignSenderMessageFolder(values) {
  return async () => {
    return await post("messages/assign_senderfolder.php", values);
  };
}

export function delMessageFolder(id) {
  return async () => {
    return await post("messagefolders/delete.php", id);
  };
}

export function clearMessageFolder(values) {
  return async () => {
    return await post("messagefolders/empty.php", values);
  };
}

export function clearSenderFolder(values) {
  return async () => {
    return await post("messagefolders/emptySenderFolder.php", values);
  };
}

export function clearRecycleBin(values) {
  return async () => {
    return await post("messagefolders/emptyRecycleBin.php", values);
  };
}

export function renMessageFolder(values) {
  return async () => {
    return await post("messagefolders/rename.php", values);
  };
}

export function insMessageFolder(values) {
  return async () => {
    return await post("messagefolders/add.php", values);
  };
}

export function person(values) {
  return async () => {
    return await post("person/read.php", values);
  };
}

export function personsMinimal(values) {
  return async () => {
    return await post(
      parseInt(values.companyId) === 0
        ? "person/read-minimal-all.php"
        : "person/read-minimal.php",
      values
    );
  };
}

export function personDetails(id) {
  return async () => {
    return await post("person/read.php", id);
  };
}

export function personsUpdate(values) {
  return async () => {
    return await post("person/update.php", values);
  };
}

export function personsAdd(values) {
  return async () => {
    return await post("person/add.php", values);
  };
}

export function personsAddTimePlan(values) {
  return async () => {
    return await post("person/add_timeplan.php", values);
  };
}

export function personsUpdateTimePlan(values) {
  return async () => {
    return await post("person/update_timeplan.php", values);
  };
}

export function personsDeleteTimePlan(values) {
  return async () => {
    return await post("person/delete_timeplan.php", values);
  };
}

export function personSetRoll(values) {
  return async () => {
    return await post("person/set_roll.php", values);
  };
}

export function entitlements() {
  return async () => {
    return await post("entitlement/read.php");
  };
}

export function entitlementAdd(values) {
  return async () => {
    return await post("entitlement/add.php", values);
  };
}

export function entitlementUpdate(values) {
  return async () => {
    return await post("entitlement/update.php", values);
  };
}

export function entitlementDelete(values) {
  return async () => {
    return await post("entitlement/delete.php", values);
  };
}

export function covstates(values) {
  return async () => {
    return await post("covstate/read.php", values);
  };
}
export function covstateAdd(values) {
  return async () => {
    return await post("covstate/add.php", values);
  };
}
export function covstateUpdate(values) {
  return async () => {
    return await post("covstate/update.php", values);
  };
}

export function offDayTypes(values) {
  return async () => {
    return await post("offdaytype/read.php", values);
  };
}

export function offDayTypeNames() {
  return async () => {
    return await post("offdaytypename/read.php");
  };
}

export function saveOffDayTypeNames(values) {
  return async () => {
    return await post("offdaytypename/save.php", values);
  };
}

export function cleanTypeNames(values) {
  return async () => {
    return await post("offdaytypename/cleanup.php", values);
  };
}

export function offDayTypeUpdate(values) {
  return async () => {
    return await post("offdaytype/update.php", values);
  };
}

export function offDayTypeAdd(values) {
  return async () => {
    return await post("offdaytype/add.php", values);
  };
}

export function offDayTypeDelete(values) {
  return async () => {
    return await post("offdaytype/delete.php", values);
  };
}

export function offDayTextUpdate(values) {
  return async () => {
    return await post("offdaytext/update.php", values);
  };
}

export function offDayTextAdd(values) {
  return async () => {
    return await post("offdaytext/add.php", values);
  };
}

export function offDayTextDelete(values) {
  return async () => {
    return await post("offdaytext/delete.php", values);
  };
}

export function offDayReasonRead(values) {
  return async () => {
    return await post("offdayreason/read.php", values);
  };
}

export function offDayReasonUpdate(values) {
  return async () => {
    return await post("offdayreason/update.php", values);
  };
}

export function offDayReasonAdd(values) {
  return async () => {
    return await post("offdayreason/add.php", values);
  };
}

export function offDayReasonDelete(values) {
  return async () => {
    return await post("offdayreason/delete.php", values);
  };
}

export function setUserOffDays(values) {
  return async () => {
    return await post("personoffdays/save.php", values);
  };
}

export function deleteUserOffDays(values) {
  return async () => {
    return await post("personoffdays/delete.php", values);
  };
}

export function requestUserOffDays(values) {
  return async () => {
    return await post("personoffdays/request.php", values);
  };
}

export function publishUserOffDays(values) {
  return async () => {
    return await post("personoffdays/publish.php", values);
  };
}

export function acceptUserOffDays(values) {
  return async () => {
    return await post("personoffdays/approve.php", values);
  };
}

export function declineUserOffDays(values) {
  return async () => {
    return await post("personoffdays/decline.php", values);
  };
}

export function setApprovementState(values) {
  return async () => {
    return await post("personoffdays/setState.php", values);
  };
}

export function userStats(values) {
  return async () => {
    return await post("userstats/read.php", values);
  };
}

export function absenceStats(values) {
  return async () => {
    return await post("userstats/read_absence.php", values);
  };
}

export function departments(values) {
  return async () => {
    return await post("department/read.php", values);
  };
}

export function departmentsMinimal() {
  return async () => {
    return await post("department/read-minimal.php");
  };
}

export function departmentsAdd(values) {
  return async () => {
    return await post("department/add.php", values);
  };
}

export function departmentsUpdate(values) {
  return async () => {
    return await post("department/update.php", values);
  };
}

export function departmentsDelete(values) {
  return async () => {
    return await post("department/delete.php", values);
  };
}

export function articles() {
  return async () => {
    return await post("article/articles/read.php");
  };
}

export function articleCreate(values) {
  return async () => {
    return await post("article/articles/create.php", values);
  };
}

export function articleUpdate(values) {
  return async () => {
    return await post("article/articles/update.php", values);
  };
}

export function articleModels() {
  return async () => {
    return await post("article/articlemodels/read.php");
  };
}

export function articleModelCreate(values) {
  return async () => {
    return await post("article/articlemodels/create.php", values);
  };
}

export function articleModelUpdate(values) {
  return async () => {
    return await post("article/articlemodels/update.php", values);
  };
}

export function articleModelDelete(values) {
  return async () => {
    return await post("article/articlemodels/delete.php", values);
  };
}

export function articleTypes() {
  return async () => {
    return await post("article/articletypes/read.php");
  };
}

export function articleTypeCreate(values) {
  return async () => {
    return await post("article/articletypes/create.php", values);
  };
}

export function articleTypeUpdate(values) {
  return async () => {
    return await post("article/articletypes/update.php", values);
  };
}

export function articleTypeDelete(values) {
  return async () => {
    return await post("article/articletypes/delete.php", values);
  };
}

export function articleReasons() {
  return async () => {
    return await post("article/articlereasons/read.php");
  };
}

export function articleReasonCreate(values) {
  return async () => {
    return await post("article/articlereasons/create.php", values);
  };
}

export function articleReasonUpdate(values) {
  return async () => {
    return await post("article/articlereasons/update.php", values);
  };
}

export function articleStates() {
  return async () => {
    return await post("article/articlestates/read.php");
  };
}

export function articleStateCreate(values) {
  return async () => {
    return await post("article/articlestates/create.php", values);
  };
}

export function articleStateUpdate(values) {
  return async () => {
    return await post("article/articlestates/update.php", values);
  };
}

export function movementTypes() {
  return async () => {
    return await post("article/movementtypes/read.php");
  };
}

export function movementTypeCreate(values) {
  return async () => {
    return await post("article/movementtypes/create.php", values);
  };
}

export function movementTypeUpdate(values) {
  return async () => {
    return await post("article/movementtypes/update.php", values);
  };
}

export function movementCreate(values) {
  return async () => {
    return await post("article/movements/create.php", values);
  };
}

export function movementUpdate(values) {
  return async () => {
    return await post("article/movements/update.php", values);
  };
}

export function movementDelete(values) {
  return async () => {
    return await post("article/movements/delete.php", values);
  };
}

export function locations() {
  return async () => {
    return await post("article/locations/read.php");
  };
}

export function locationCreate(values) {
  return async () => {
    return await post("article/locations/create.php", values);
  };
}

export function locationUpdate(values) {
  return async () => {
    return await post("article/locations/update.php", values);
  };
}

export function companyLocations() {
  return async () => {
    return await post("company/read.php");
  };
}

export function companyLocationAdd(values) {
  return async () => {
    return await post("company/add.php", values);
  };
}

export function companyLocationUpdate(values) {
  return async () => {
    return await post("company/update.php", values);
  };
}

export function logoUpload(values) {
  return async () => {
    return await post_image("company/saveLogo.php", values);
  };
}

export function grouptypes() {
  return async () => {
    return await post("grouptype/read.php");
  };
}

export function specialDays() {
  return async () => {
    return await post("specialday/read_special.php");
  };
}

export function specialDaysLocal(values) {
  return async () => {
    return await post("specialday/read_specialdays.php", values);
  };
}

export function specialDayCreate(values) {
  return async () => {
    return await post("specialday/add.php", values);
  };
}

export function specialDayUpdate(values) {
  return async () => {
    return await post("specialday/update.php", values);
  };
}

export function specialDayDelete(values) {
  return async () => {
    return await post("specialday/delete.php", values);
  };
}

export function remarkableDays() {
  return async () => {
    return await post("specialday/read_remarkable.php");
  };
}

export function remarkableDayUpdate(values) {
  return async () => {
    return await post("specialday/update.php", values);
  };
}

export function holidays(values) {
  return async () => {
    return await post("holiday/read.php", values);
  };
}

export function holidayUpdate(values) {
  return async () => {
    return await post("holiday/update.php", values);
  };
}

export function holidayAdd(values) {
  return async () => {
    return await post("holiday/add.php", values);
  };
}

export function holidayDelete(values) {
  return async () => {
    return await post("holiday/delete.php", values);
  };
}

export function daytypes(values) {
  return async () => {
    return await post("daytype/read.php", values);
  };
}

export function daytypeCreate(values) {
  return async () => {
    return await post("daytype/create.php", values);
  };
}

export function daytypeUpdate(values) {
  return async () => {
    return await post("daytype/update.php", values);
  };
}

export function daytypeDelete(values) {
  return async () => {
    return await post("daytype/delete.php", values);
  };
}

export function wages(values) {
  return async () => {
    return await post("salary/read.php", values);
  };
}

export function salaryAdd(values) {
  return async () => {
    return await post("salary/add.php", values);
  };
}

export function salaryUpdate(values) {
  return async () => {
    return await post("salary/update.php", values);
  };
}

export function salaryDelete(values) {
  return async () => {
    return await post("salary/delete.php", values);
  };
}

export function companiesRead() {
  return async () => {
    return await post("companies/read.php");
  };
}

export function companiesSave(values) {
  return async () => {
    return await post("companies/save.php", values);
  };
}

export function companiesDelete(values) {
  return async () => {
    return await post("companies/delete.php", values);
  };
}

export function industriesRead() {
  return async () => {
    return await post("industry/read.php");
  };
}

export function industrySave(values) {
  return async () => {
    return await post("industry/save.php", values);
  };
}

export function industryDelete(values) {
  return async () => {
    return await post("industry/delete.php", values);
  };
}

export function countriesRead() {
  return async () => {
    return await post("country/read.php");
  };
}

export function countrySave(values) {
  return async () => {
    return await post("country/save.php", values);
  };
}

export function regionAdd(values) {
  return async () => {
    return await post("country/add_region.php", values);
  };
}

export function regionUpdate(values) {
  return async () => {
    return await post("country/update_region.php", values);
  };
}

export function regionDelete(values) {
  return async () => {
    return await post("country/delete_region.php", values);
  };
}

export function reminders(values) {
  return async () => {
    return await post("reminder/read.php", values);
  };
}

export function reminderAdd(values) {
  return async () => {
    return await post("reminder/add.php", values);
  };
}

export function reminderUpdate(values) {
  return async () => {
    return await post("reminder/update.php", values);
  };
}

export function reminderDelete(values) {
  return async () => {
    return await post("reminder/delete.php", values);
  };
}

export function reminderSetAlert(values) {
  return async () => {
    return await post("reminder/alertsent.php", values);
  };
}

export function reminderSetAlertTime(values) {
  return async () => {
    return await post("reminder/setalert.php", values);
  };
}

export function remindertimes() {
  return async () => {
    return await post("remindertimes/read.php");
  };
}

export function reminderCategories(values) {
  return async () => {
    return await post("remindercategory/read.php", values);
  };
}

export function reminderCategoryAdd(values) {
  return async () => {
    return await post("remindercategory/add.php", values);
  };
}

export function reminderCategoryUpdate(values) {
  return async () => {
    return await post("remindercategory/update.php", values);
  };
}

export function reminderCategoryDelete(values) {
  return async () => {
    return await post("remindercategory/delete.php", values);
  };
}

export function globalSettingsRead(values) {
  return async () => {
    return await post("globalsettings/read.php", values);
  };
}

export function globalSettingsSet(values) {
  return async () => {
    return await post("globalsettings/set.php", values);
  };
}

export function remarks() {
  return async () => {
    return await post("remark/read.php");
  };
}

export function remarkAdd(values) {
  return async () => {
    return await post("remark/create.php", values);
  };
}

export function remarkUpdate(values) {
  return async () => {
    return await post("remark/update.php", values);
  };
}

export function remarkDelete(values) {
  return async () => {
    return await post("remark/delete.php", values);
  };
}

/* Time Tracking */
export function getTimes(values) {
  return async () => {
    return await post("time/read.php", values);
  };
}

export function getLastTimes(values) {
  return async () => {
    return await post("time/read_last.php", values);
  };
}

export function getTimesDay(values) {
  return async () => {
    return await post("time/read_day.php", values);
  };
}

export function getTimesCum(values) {
  return async () => {
    return await post("time/read_cum.php", values);
  };
}

export function getTimesFaulty(values) {
  return async () => {
    return await post("time/read_cum_faulty.php", values);
  };
}

export function getTimesMonth(values) {
  return async () => {
    return await post("time/read_month.php", values);
  };
}

export function setTimeFromWeb(values) {
  return async () => {
    return await post("time/setWebTime.php", values);
  };
}

export function setStartTime(values) {
  return async () => {
    return await post("time/add.php", values);
  };
}

export function setEndTime(values) {
  return async () => {
    return await post("time/save.php", values);
  };
}

export function saveAliveTime(values) {
  return async () => {
    return await post("time/save_alive.php", values);
  };
}

export function readTimeSums(values) {
  return async () => {
    return await post("time/read_sums.php", values);
  };
}

export function getAliveTime(values) {
  return async () => {
    return await post("time/read_alive.php", values);
  };
}

export function getPersonState(values) {
  return async () => {
    return await post("time/read_person_state.php", values);
  };
}

export function timeUpdate(values) {
  return async () => {
    return await post("time/update.php", values);
  };
}

export function timeDelete(values) {
  return async () => {
    return await post("time/delete.php", values);
  };
}

export function timeAdd(values) {
  return async () => {
    return await post("time/new.php", values);
  };
}

export function notTerminated() {
  return async () => {
    return await post("time/read_not_terminated.php");
  };
}

export function dayTermination(values) {
  return async () => {
    return await post("time/daytermination.php", values);
  };
}

export function dayTerminationUser(values) {
  return async () => {
    return await post("time/dayterminationuser.php", values);
  };
}

export function monthTermination(values) {
  return async () => {
    return await post("time/monthtermination.php", values);
  };
}

export function resetMonthTermination(values) {
  return async () => {
    return await post("time/resetmonthtermination.php", values);
  };
}

export function getMonthTerminated(values) {
  return async () => {
    return await post("time/read_month_terminated.php", values);
  };
}

export function getCurrentTimeState(values) {
  return async () => {
    return await post("time/get_state.php", values);
  };
}

/* Recurring absences */

export function recurringAbsencesRead(values) {
  return async () => {
    return await post("recurringabsence/read.php", values);
  };
}

export function recurringAbsenceCreate(values) {
  return async () => {
    return await post("recurringabsence/create.php", values);
  };
}

export function recurringAbsenceUpdate(values) {
  return async () => {
    return await post("recurringabsence/update.php", values);
  };
}

export function recurringAbsenceDelete(values) {
  return async () => {
    return await post("recurringabsence/delete.php", values);
  };
}

export function recurringAbsenceSplit(values) {
  return async () => {
    return await post("recurringabsence/split.php", values);
  };
}

/* Exclusions from recurring Absences */
export function recurringAbsenceExclusionsRead() {
  return async () => {
    return await post("excludedrecurringabsence/read.php");
  };
}

export function recurringAbsenceExclusionCreate(values) {
  return async () => {
    return await post("excludedrecurringabsence/create.php", values);
  };
}

export function recurringAbsenceExclusionsDelete(values) {
  return async () => {
    return await post("excludedrecurringabsence/delete.php", values);
  };
}

/* Daily time plans */

export function dailyTimePlansRead(values) {
  return async () => {
    return await post("dailytimeplan/read.php", values);
  };
}

export function dailyTimePlanCreate(values) {
  return async () => {
    return await post("dailytimeplan/create.php", values);
  };
}

export function dailyTimePlanUpdate(values) {
  return async () => {
    return await post("dailytimeplan/update.php", values);
  };
}

export function dailyTimePlanDelete(values) {
  return async () => {
    return await post("dailytimeplan/delete.php", values);
  };
}

/* Weekly time plans */

export function weeklyTimePlansRead(values) {
  return async () => {
    return await post("weeklytimeplan/read.php", values);
  };
}

export function weeklyTimePlanCreate(values) {
  return async () => {
    return await post("weeklytimeplan/create.php", values);
  };
}

export function weeklyTimePlanUpdate(values) {
  return async () => {
    return await post("weeklytimeplan/update.php", values);
  };
}

export function weeklyTimePlanDelete(values) {
  return async () => {
    return await post("weeklytimeplan/delete.php", values);
  };
}

export function fetchTimeClockData(values) {
  return async () => {
    return await post("timeclock/read.php", values);
  };
}

export function addTimeClockData(values) {
  return async () => {
    return await post("timeclock/add.php", values);
  };
}

export function saveTimeClockData(values) {
  return async () => {
    return await post("timeclock/update.php", values);
  };
}

export function deleteTimeClockData(values) {
  return async () => {
    return await post("timeclock/delete.php", values);
  };
}

/* Exit reasons for time clocks */
export function exitReasons(values) {
  return async () => {
    return await post("exitreason/read.php", values);
  };
}

export function exitReasonCreate(values) {
  return async () => {
    return await post("exitreason/create.php", values);
  };
}

export function exitReasonUpdate(values) {
  return async () => {
    return await post("exitreason/update.php", values);
  };
}

export function exitReasonDelete(values) {
  return async () => {
    return await post("exitreason/delete.php", values);
  };
}

/* Roll based security */
export function rollsRead(values) {
  return async () => {
    return await post("rolls/read.php", values);
  };
}

export function rollCreate(values) {
  return async () => {
    return await post("rolls/create.php", values);
  };
}

export function rollUpdate(values) {
  return async () => {
    return await post("rolls/update.php", values);
  };
}

export function rollDelete(values) {
  return async () => {
    return await post("rolls/delete.php", values);
  };
}

export function rightsCopy(values) {
  return async () => {
    return await post("rolls/copyRights.php", values);
  };
}

export function sendmail(values) {
  return async () => {
    return await post("mail/sendmail.php", values);
  };
}

/* Change Log */
export function changeLogRead(values) {
  return async () => {
    return await post("changeLog/read.php", values);
  };
}

export function changeLogCreate(values) {
  return async () => {
    return await post("changeLog/create.php", values);
  };
}

export function changeLogUpdate(values) {
  return async () => {
    return await post("changeLog/update.php", values);
  };
}

export function changeLogDelete(values) {
  return async () => {
    return await post("changeLog/delete.php", values);
  };
}

/* Projects */
export function projectsRead(values) {
  return async () => {
    return await post("project/read.php", values);
  };
}
export function projectCreate(values) {
  return async () => {
    return await post("project/create.php", values);
  };
}
export function projectUpdate(values) {
  return async () => {
    return await post("project/update.php", values);
  };
}
export function projectDelete(values) {
  return async () => {
    return await post("project/delete.php", values);
  };
}

/* Documents */
export function docsReadTitles(values) {
  return async () => {
    return await post("document/read_titles.php", values);
  };
}
export function docRead(values) {
  return async () => {
    return await post("document/read.php", values);
  };
}
export function docCreate(values) {
  return async () => {
    return await post("document/create.php", values);
  };
}
export function docUpdate(values) {
  return async () => {
    return await post("document/update.php", values);
  };
}
export function docDelete(values) {
  return async () => {
    return await post("document/delete.php", values);
  };
}
