import i18n from "i18next";
import { initReactI18next } from "react-i18next";

var usedTranslation = {};

switch(localStorage.getItem("lang")){
  case "de-DE":
    usedTranslation = require("locales/de/translation.json");
    break
  case "cs-CZ":
    usedTranslation = require("locales/cz/translation.json");
    break
  case "en-US":
    usedTranslation = require("locales/en/translation.json");
    break
  default:
    usedTranslation = require("locales/de/translation.json");  
}
// if (localStorage.getItem("lang") === "de-DE") {
//   usedTranslation = require("locales/de/translation.json");
// } else {
//   usedTranslation = require("locales/cz/translation.json");
// }

const resources = usedTranslation

i18n.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: "en-US",
  debug: false,
  keySeparator: ">",
  nsSeparator: "|",
});
export default i18n;
