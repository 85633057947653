import React, { useEffect, useState, useRef } from "react";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import {
  setWebTime,
  fetchPersonState,
  fetchTimeState,
  fetchOffDayTypes,
  fetchToday,
} from "../redux/index";
import { useTranslation } from "react-i18next";
import {
  getGlobalSetting,
  getPersonalSetting,
  minutesToTime,
  minutesToShowTime,
  minutesFromTime,
} from "Components/Utilities";
import { addLogEntry } from "Components/Utilities";
import Collapse from "@mui/material/Collapse";
import SomuraTooltip from "Components/SomuraTooltip";

import IconButton from "@mui/material/IconButton";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AnalogClock from "./AnalogClock";

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggeduser.LoggedUser,
    globalSettings: state.settings.GlobalSettings,
    personalSettings: state.settings.PersonalSettings,
    times: state.times.Times,
    aliveTime: state.times.aliveTime,
    monthStatic: state.month.MonthStatic,
    monthOffDays: state.month.MonthUsers,
    offDayTypes: state.offdaytypes.OffDayTypes,
    firstSecond: state.general.FirstSecond,
    myState: state.times.MyState,
    stats: state.general.Stats,
    todayState: state.today.TodayStatic,
    selectedLocation: state.selections.selectedLocationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWebTime: (values) => dispatch(setWebTime(values)),
    fetchPersonState: (values) => dispatch(fetchPersonState(values)),
    fetchTimeState: (values) => dispatch(fetchTimeState(values)),
    fetchOffDayTypes: (values) => dispatch(fetchOffDayTypes(values)),
    fetchToday: (values) => dispatch(fetchToday(values)),
  };
};

const ShowTime = ({
  loggedUser,
  personalSettings,
  times,
  setWebTime,
  fetchPersonState,
  fetchTimeState,
  fetchOffDayTypes,
  fetchToday,
  monthStatic,
  monthOffDays,
  offDayTypes,
  myState,
  stats,
  todayState,
  selectedLocation,
}) => {
  const { t } = useTranslation();
  const timeRef = useRef();

  const autoStarted = useRef(false);
  const myLocation = localStorage.getItem("location")

  const workOnSaturday =
    parseInt(getGlobalSetting("system", "workOnSaturday")) === 1;

  let offDay = useRef(false);
  let displayText = useRef("");
  let workTimeRunning = useRef(false);
  let breakTimeRunning = useRef(false);
  let firstStart = useRef("");
  let timeStart = useRef(0);
  let timeCurrent = useRef(0);
  let breakCurrent = useRef(0);
  let workMinutesWeek = useRef(0);
  let deductionMinutesWeek = useRef(0);
  let workMinutesMonth = useRef(0);
  let deductionMinutesMonth = useRef(0);
  let blinkingColon = useRef(
    parseInt(getPersonalSetting("timetracking", "blinkingColon")) === 1
  );
  let analogClockIndicator = useRef(
    parseInt(getPersonalSetting("timetracking", "analogClockIndicator")) === 1
  );
  let autoStart = useRef(
    parseInt(getPersonalSetting("timetracking", "autoStart")) === 1
  );
  let restartAfterMidnight = useRef(
    parseInt(getPersonalSetting("timetracking", "restartAfterMidnight")) === 1
  );
  let worktimeColor = useRef(
    getPersonalSetting("timetracking", "worktimeColor")
  );
  let breaktimeColor = useRef(
    getPersonalSetting("timetracking", "breaktimeColor")
  );
  let warnMinTime = useRef(
    parseInt(getPersonalSetting("timetracking", "warnMinTime")) === 1
  );
  let warnMinTimeColor = useRef(
    getPersonalSetting("timetracking", "warnMinTimeColor")
  );
  let warnMaxTime = useRef(
    parseInt(getPersonalSetting("timetracking", "warnMaxTime")) === 1
  );
  let warnMaxTimeColor = useRef(
    getPersonalSetting("timetracking", "warnMaxTimeColor")
  );
  let displayWorktimeColor = useRef(worktimeColor.current);
  const [showWeekMonth, setShowWeekMonth] = useState(false);
  const [displayWorkTime, setDisplayWorkTime] = useState("00:00");
  const [displayBreakTime, setDisplayBreakTime] = useState("00:00");
  const [displayWeekTime, setDisplayWeekTime] = useState("00:00");
  const [displayMonthTime, setDisplayMonthTime] = useState("00:00");
  const [displayTimes, setDisplayTimes] = useState(true);
  const [midnightPassed, setMidnightPassed] = useState(false);

  const today = useRef(DateTime.now());

  const todayMinMinutes = useRef(
    loggedUser?.dailyTimePlans?.find((DP) => DP.day === today.current.weekday)
      ?.minMinutes
  );
  const todayMaxMinutes = useRef(
    loggedUser?.dailyTimePlans?.find((DP) => DP.day === today.current.weekday)
      ?.maxMinutes
  );
  const minTimeReached = useRef(false);
  const maxTimeReached = useRef(false);
  const handleDisplayTimes = () => {
    setDisplayTimes(!displayTimes);
    setShowWeekMonth(false);
  };

  const getState = () => {
    // Intended to check wether time state has changed through time clock
    setTimeout(function () {
      fetchTimeState({
        userId: loggedUser.id,
        date: DateTime.now().toSQLDate(),
      });
    }, 500);
  };

  const startWorkTime = () => {
    addLogEntry({
      who: loggedUser.listName,
      what: "Timetracking: worktime manually started",
      locationId: myLocation,
    });
    // Close additional display
    if (showWeekMonth) {
      setShowWeekMonth(false);
    }
    // Play-Button pressed
    if (breakTimeRunning.current) {
      // Stop Break
      breakTimeRunning.current = false;
      addLogEntry({
        who: loggedUser.listName,
        what: "Timetracking: breaktime manually stopped",
        locationId: myLocation,
      });
    }
    // Start Work time
    workTimeRunning.current = true;
    setWebTime({
      userId: loggedUser.id,
      date: DateTime.now().toSQLDate(),
      minutes: midnightPassed
        ? 0
        : parseInt(DateTime.now().toFormat("H")) * 60 +
          parseInt(DateTime.now().toFormat("m")),
      isBreak: "0",
    });
    setMidnightPassed(false);
    getState();
  };

  const endWorkTime = () => {
    // Stop-Button pressed
    addLogEntry({
      who: loggedUser.listName,
      what: "Timetracking: worktime manually stopped",
      locationId: myLocation,
    });
    // Close additional display
    if (showWeekMonth) {
      setShowWeekMonth(false);
    }
    workTimeRunning.current = false;
    setWebTime({
      userId: loggedUser.id,
      date: midnightPassed
        ? DateTime.now().minus({ days: 1 }).toSQLDate()
        : DateTime.now().toSQLDate(),
      minutes: midnightPassed
        ? 1439
        : parseInt(DateTime.now().toFormat("H")) * 60 +
          parseInt(DateTime.now().toFormat("m")),
      isBreak: "0",
    });
    if (midnightPassed === true && restartAfterMidnight.current === false) {
      setMidnightPassed(false);
    }
    getState();
  };

  const startBreak = () => {
    // Pause-Button pressed
    // Stop Work time
    workTimeRunning.current = false;
    // Start Break
    breakTimeRunning.current = true;
    setWebTime({
      userId: loggedUser.id,
      date: DateTime.now().toSQLDate(),
      minutes:
        parseInt(DateTime.now().toFormat("H")) * 60 +
        parseInt(DateTime.now().toFormat("m")),
      isBreak: "1",
    });
    addLogEntry({
      who: loggedUser.listName,
      what: "Timetracking: breaktime manually started",
      locationId: myLocation,
    });
    // Close additional display
    if (showWeekMonth) {
      setShowWeekMonth(false);
    }
    getState();
  };

  const endBreak = () => {
    // Stop-Button pressed
    addLogEntry({
      who: loggedUser.listName,
      what: "Timetracking: breaktime automatically stopped",
      locationId: myLocation,
    });
    // Close additional display
    if (showWeekMonth) {
      setShowWeekMonth(false);
    }
    breakTimeRunning.current = false;
    setWebTime({
      userId: loggedUser.id,
      date: DateTime.now().toSQLDate(),
      minutes:
        parseInt(DateTime.now().toFormat("H")) * 60 +
        parseInt(DateTime.now().toFormat("m")),
      isBreak: "1",
    });
  };

  const setDisplayTime = () => {
    const currTime = DateTime.now().toFormat("HH:mm");
    const currDate = DateTime.now().toSQLDate();
    var timeWasRunning = false;

    // Check if date has changed (midnight)
    if (currDate === today.current.minus({ days: 1 }).toSQLDate()) {
      // Stop the running worktime
      if (workTimeRunning.current === true) {
        setMidnightPassed(true);
        timeWasRunning = true;
        endWorkTime();
      }
      if (breakTimeRunning.current === true) {
        setMidnightPassed(true);
        endBreak();
      }
      // Reset the display
      setDisplayWorkTime("00:00");
      setDisplayBreakTime("00:00");
      // Set the new date
      today.current = DateTime.now();
      // Set new minimum worktime
      todayMinMinutes.current = loggedUser?.dailyTimePlans.find(
        (DP) => DP.day === today.current.weekday
      ).minMinutes;
      // Set new maximum worktime
      todayMaxMinutes.current = loggedUser?.dailyTimePlans.find(
        (DP) => DP.day === today.current.weekday
      ).maxMinutes;
      // Check weekend or absences
      checkAbsence();
      // Restart worktime according to settings
      if (
        timeWasRunning === true &&
        restartAfterMidnight.current === true &&
        displayText.current === ""
      ) {
        startWorkTime();
      }
    } else {
      setMidnightPassed(false);
    }
    const diffMinutes = timeStart.current
      ? minutesFromTime(currTime) - timeStart.current
      : 0;
    minTimeReached.current =
      timeCurrent.current + diffMinutes >= todayMinMinutes.current;
    maxTimeReached.current =
      timeCurrent.current + diffMinutes >= todayMaxMinutes.current;
    // Set the correct display colors
    displayWorktimeColor.current = worktimeColor.current;
    if (minTimeReached.current && warnMinTime.current) {
      displayWorktimeColor.current = warnMinTimeColor.current;
    }
    if (maxTimeReached.current && warnMaxTime.current) {
      displayWorktimeColor.current = warnMaxTimeColor.current;
    }
    setDisplayWorkTime(
      minutesToShowTime(
        timeCurrent.current + (workTimeRunning.current ? diffMinutes : 0),
        workTimeRunning.current ? blinkingColon.current : false
      )
    );
    setDisplayWeekTime(
      minutesToShowTime(
        workMinutesWeek.current +
          timeCurrent.current +
          (workTimeRunning.current ? diffMinutes : 0),
        workTimeRunning.current ? blinkingColon.current : false,
        "W "
      )
    );
    setDisplayMonthTime(
      minutesToShowTime(
        workMinutesMonth.current +
          timeCurrent.current +
          (workTimeRunning.current ? diffMinutes : 0),
        workTimeRunning.current ? blinkingColon.current : false,
        "M "
      )
    );
    setDisplayBreakTime(
      minutesToShowTime(
        breakCurrent.current + (breakTimeRunning.current ? diffMinutes : 0),
        breakTimeRunning.current ? blinkingColon.current : false
      )
    );
  };

  const handleTimeClick = () => {
    setShowWeekMonth(!showWeekMonth);
  };

  const checkAbsence = () => {
    // Check if this day (today.current) is offDay
    displayText.current = "";
    // Weekend?
    if (
      (today.current.weekday === 6 && !workOnSaturday) ||
      today.current.weekday === 7
    ) {
      offDay.current = true;
      displayText.current = t("Weekend");
    }
    // Holiday?
    if (
      monthStatic?.findIndex(
        (MS) =>
          MS.date === today.current.toSQLDate() && parseInt(MS.dayFactor) === 0
      ) > -1
    ) {
      offDay.current = true;
      displayText.current = monthStatic?.find(
        (MS) =>
          MS.date === today.current.toSQLDate() && parseInt(MS.dayFactor) === 0
      ).specialDayName;
    }
    // Absence day?
    if(todayState.absence !== ''){
      offDay.current = true
      displayText.current = todayState.absence;
    }
    if (offDay.current === true) {
      workTimeRunning.current = false;
      breakTimeRunning.current = false;
    }
  };

  useEffect(() => {
    fetchOffDayTypes({ locationId: selectedLocation });
    fetchToday({ userId: loggedUser?.id});
    setDisplayTime();
    //eslint-disable-next-line
  }, [selectedLocation, stats.reloadUserTime]);

  useEffect(() => {
    if (myState !== undefined) {
      timeCurrent.current = parseInt(myState.workMinutes);
      breakCurrent.current = parseInt(myState.breakMinutes);
      workTimeRunning.current = myState.worktimeRunning;
      breakTimeRunning.current = myState.breaktimeRunning;
      if (myState.worktimeRunning || myState.breaktimeRunning) {
        timeStart.current = myState.startMinutes;
      }
      if (
        parseInt(myState.workMinutes) === 0 &&
        parseInt(myState.breakMinutes) === 0
      ) {
        firstStart.current = minutesToTime(myState.startMinutes);
      } else {
        firstStart.current = minutesToTime(
          times.find(
            (T) =>
              DateTime.fromSQL(T.date).toSQLDate() === today.current.toSQLDate()
          )?.startMinutes
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myState]);

  useEffect(() => {
    if (stats.endMinutes === 0) {
      workTimeRunning.current = stats.isWorktime;
      breakTimeRunning.current = stats.isBreak;
    } else {
      workTimeRunning.current = false;
      breakTimeRunning.current = false;
    }
  }, [stats]);

  useEffect(() => {
    if (loggedUser.id > 0) {
      fetchPersonState({ userId: loggedUser.id });
      deductionMinutesWeek.current = times
        .filter(
          (T) =>
            DateTime.fromSQL(T.date).toSQLDate() !==
              today.current.toSQLDate() &&
            DateTime.fromSQL(T.date).toFormat("y-W") ===
              today.current.toFormat("y-W") &&
            T.isAutoBreak
        )
        .reduce((a, b) => a + parseInt(b.minutes), 0);
      workMinutesWeek.current =
        times
          .filter(
            (T) =>
              DateTime.fromSQL(T.date).toSQLDate() !==
                today.current.toSQLDate() &&
              DateTime.fromSQL(T.date).toFormat("y-W") ===
                today.current.toFormat("y-W") &&
              T.isWorktime
          )
          .reduce((a, b) => a + parseInt(b.minutes), 0) -
        deductionMinutesWeek.current;
      deductionMinutesMonth.current = times
        .filter(
          (T) =>
            DateTime.fromSQL(T.date).toSQLDate() !==
              today.current.toSQLDate() &&
            DateTime.fromSQL(T.date).toFormat("y-M") ===
              today.current.toFormat("y-M") &&
            T.isAutoBreak
        )
        .reduce((a, b) => a + parseInt(b.minutes), 0);
      workMinutesMonth.current =
        times
          .filter(
            (T) =>
              DateTime.fromSQL(T.date).toSQLDate() !==
                today.current.toSQLDate() &&
              DateTime.fromSQL(T.date).toFormat("y-M") ===
                today.current.toFormat("y-M") &&
              T.isWorktime
          )
          .reduce((a, b) => a + parseInt(b.minutes), 0) -
        deductionMinutesMonth.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, stats.reloadUserTime]);

  useEffect(() => {
    if (personalSettings) {
      autoStart.current =
        parseInt(getPersonalSetting("timetracking", "autoStart")) === 1;
      blinkingColon.current =
        parseInt(getPersonalSetting("timetracking", "blinkingColon")) === 1;
      analogClockIndicator.current =
        parseInt(getPersonalSetting("timetracking", "analogClockIndicator")) ===
        1;
      restartAfterMidnight.current = parseInt(
        getPersonalSetting("timetracking", "restartAfterMidnight")
      );
      worktimeColor.current = getPersonalSetting(
        "timetracking",
        "worktimeColor"
      );
      breaktimeColor.current = getPersonalSetting(
        "timetracking",
        "breaktimeColor"
      );
      warnMinTime.current =
        parseInt(getPersonalSetting("timetracking", "warnMinTime")) === 1;
      warnMinTimeColor.current = getPersonalSetting(
        "timetracking",
        "warnMinTimeColor"
      );
      warnMaxTime.current =
        parseInt(getPersonalSetting("timetracking", "warnMaxTime")) === 1;
      warnMaxTimeColor.current = getPersonalSetting(
        "timetracking",
        "warnMaxTimeColor"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalSettings]);

  useEffect(() => {
    if (
      // Object.keys(monthStatic).length > 0 &&
      // Object.keys(monthOffDays).length > 0 &&
      Object.keys(offDayTypes).length > 0
    ) {
      if (
        !myState.worktimeRunning &&
        !myState.breaktimeRunning &&
        timeCurrent.current === 0
      ) {
        checkAbsence();
        if (autoStart.current === true && displayText.current === "") {
          if (autoStarted.current === false) {
            autoStarted.current = true;
            addLogEntry({
              who: "System",
              what:
                "Timetracking: worktime automatically started for " +
                loggedUser.listName,
              locationId: myLocation,
            });
            setWebTime({
              userId: loggedUser.id,
              date: DateTime.now().toSQLDate(),
              minutes:
                parseInt(DateTime.now().toFormat("H")) * 60 +
                parseInt(DateTime.now().toFormat("m")),
              isBreak: "0",
            });
            workTimeRunning.current = true;
            breakTimeRunning.current = false;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [stats, offDayTypes]);

  useEffect(() => {
    timeRef.current = setInterval(() => {
      setDisplayTime();
    }, 1000);
    return () => {
      clearInterval(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 3,
          width: "fit-content",
          height: "2.2rem",
          margin: "auto 5px auto 15px",
          padding: "10px 10px",
          borderRadius: "0.5rem",
          boxShadow: "inset 3px 3px 3px 1px rgba(0, 0, 0, 0.4)",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!workTimeRunning.current && (
          <SomuraTooltip
            title={t("Start work time")}
            placement="bottom"
            disabled={offDay.current || displayText.current !== ""}
          >
            <span>
              <IconButton
                sx={{
                  marginRight: "12px",
                  width: "0.8rem",
                  height: "0.8rem",
                  color: "white",
                }}
                onClick={startWorkTime}
                disabled={offDay.current || displayText.current !== ""}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </span>
          </SomuraTooltip>
        )}

        {workTimeRunning.current && (
          <SomuraTooltip title={t("Start break")} placement="bottom">
            <span>
              <IconButton
                sx={{
                  marginRight: "12px",
                  width: "0.8rem",
                  height: "0.8rem",
                  color: "white",
                }}
                onClick={startBreak}
              >
                <PauseCircleOutlineOutlinedIcon />
              </IconButton>
            </span>
          </SomuraTooltip>
        )}

        {workTimeRunning.current && (
          <SomuraTooltip title={t("End work time")} placement="bottom">
            <span>
              <IconButton
                sx={{
                  marginRight: "12px",
                  width: "0.8rem",
                  height: "0.8rem",
                  color: "white",
                }}
                onClick={endWorkTime}
                disabled={offDay.current}
              >
                <StopCircleOutlinedIcon />
              </IconButton>
            </span>
          </SomuraTooltip>
        )}
        {displayText.current !== "" ? (
          <Box
            sx={{
              fontSize: "1.2rem",
              fontWeight: "400",
              whiteSpace: "nowrap",
              cursor: "pointer",
              color: workTimeRunning.current ? worktimeColor.current : "grey",
              border: "none",
            }}
            onClick={handleTimeClick}
          >
            {displayText.current}
          </Box>
        ) : (
          <>
            {displayTimes && (
              <SomuraTooltip
                title={
                  firstStart.current === "Invalid DateTime"
                    ? t("Work time")
                    : t("Work time since") + " " + firstStart.current
                }
                placement="bottom"
              >
                <Box
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    whiteSpace: "nowrap",
                    cursor: "pointer",

                    color: workTimeRunning.current
                      ? displayWorktimeColor.current
                      : "grey",
                    border: "none",
                  }}
                  onClick={handleTimeClick}
                >
                  {!displayWorkTime ||
                  (displayWorkTime + "").indexOf("N") !== -1
                    ? ""
                    : displayWorkTime}
                </Box>
              </SomuraTooltip>
            )}
            {displayTimes &&
              (workTimeRunning.current || breakTimeRunning.current) &&
              (analogClockIndicator.current ? (
                <AnalogClock
                  secondsColor={
                    workTimeRunning.current
                      ? displayWorktimeColor.current
                      : breaktimeColor.current
                  }
                />
              ) : (
                <CommitOutlinedIcon
                  sx={{
                    margin: "0 6px",
                    transition: "1s",
                    "@keyframes rotating": {
                      from: {
                        transform: "rotate(0deg)",
                      },
                      to: {
                        transform: "rotate(180deg)",
                      },
                    },
                    animation: "rotating 30s steps(30, end) infinite",
                    color: workTimeRunning.current
                      ? displayWorktimeColor.current
                      : breaktimeColor.current,
                  }}
                />
              ))}
            {displayTimes &&
              !workTimeRunning.current &&
              !breakTimeRunning.current && (
                <CommitOutlinedIcon
                  sx={{
                    color: "grey",
                  }}
                />
              )}
            {!displayTimes && workTimeRunning.current && (
              <Box
                sx={{
                  width: "20px",
                  height: "1rem",
                  marginRight: "-16px",
                }}
              >
                <Box
                  sx={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "2px",
                    marginTop: "1.3rem",
                    "@keyframes MoveLeftRight": {
                      "0%, 100%": {
                        marginLeft: "-3.8rem",
                      },
                      "50%": {
                        marginLeft: "1.2rem",
                      },
                    },
                    transition: "1s",
                    animation: "MoveLeftRight 4s linear infinite",
                    backgroundColor: worktimeColor.current,
                  }}
                ></Box>
              </Box>
            )}
            {!displayTimes && breakTimeRunning.current && (
              <Box
                sx={{
                  width: "20px",
                  height: "1rem",
                  marginRight: "-16px",
                }}
              >
                <Box
                  sx={{
                    width: "4px",
                    height: "4px",
                    borderRadius: "2px",
                    marginTop: "1.3rem",
                    "@keyframes MoveLeftRightSmall": {
                      "0%, 100%": {
                        marginLeft: "-2rem",
                      },
                      "50%": {
                        marginLeft: "1.2rem",
                      },
                    },
                    transition: "1s",
                    animation: "MoveLeftRightSmall 4s linear infinite",
                    backgroundColor: breaktimeColor.current,
                  }}
                ></Box>
              </Box>
            )}
            {displayTimes && (
              <SomuraTooltip title={t("Break time")} placement="bottom">
                <Box
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    color: breakTimeRunning.current
                      ? breaktimeColor.current
                      : "grey",
                    border: "none",
                  }}
                  onClick={handleTimeClick}
                >
                  {!displayBreakTime ||
                  (displayBreakTime + "").indexOf("N") !== -1
                    ? ""
                    : displayBreakTime}
                </Box>
              </SomuraTooltip>
            )}
            {displayTimes && (
              <SomuraTooltip title={t("Hide times")} placement="bottom">
                <IconButton
                  sx={{
                    marginLeft: "12px",
                    width: "0.8rem",
                    height: "0.8rem",
                    color: "white",
                  }}
                  style={{ color: "#360f8a" }}
                  onClick={handleDisplayTimes}
                >
                  <VisibilityOffOutlinedIcon />
                </IconButton>
              </SomuraTooltip>
            )}
            {!displayTimes && (
              <SomuraTooltip title={t("Show times")} placement="bottom">
                <IconButton
                  sx={{
                    width: "0.8rem",
                    height: "0.8rem",
                    color: "white",
                  }}
                  style={{ color: "#360f8a" }}
                  onClick={handleDisplayTimes}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </SomuraTooltip>
            )}
          </>
        )}
      </Box>
      <Collapse
        sx={{
          height: "4.4rem",
          margin: "3.3rem 5px auto 84px",
          padding: "10px 10px",
          borderRadius: "0.5rem",
          boxShadow: "inset 3px 3px 3px 1px rgba(0, 0, 0, 0.4)",
          backgroundColor: "somura",
          position: "absolute",
        }}
        in={showWeekMonth}
        timeout={50}
        onClick={handleTimeClick}
      >
        <span>
          <Box
            component="span"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "400",
              whiteSpace: "nowrap",
              cursor: "pointer",
              color: workTimeRunning.current ? worktimeColor.current : "grey",
            }}
          >
            {displayWeekTime}
          </Box>
        </span>
        <div>
          <Box
            component="span"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "400",
              whiteSpace: "nowrap",
              cursor: "pointer",
              color: workTimeRunning.current ? worktimeColor.current : "grey",
            }}
          >
            {displayMonthTime}
          </Box>
        </div>
      </Collapse>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTime);
