import {
  FETCH_MESSAGEFOLDERS_SUCCESS,
  FETCH_MESSAGEFOLDERS_FAILURE,
  SET_SELECTED_FOLDER_ID,
  DELETE_MESSAGEFOLDER,
  ADD_MESSAGEFOLDER,
  RENAME_MESSAGEFOLDER,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  MessageFolders: [],
  error: "",
  selectedFolderId: "0",
};

const messageFoldersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key;
    switch (action.type) {
      case FETCH_MESSAGEFOLDERS_SUCCESS:
        
        draft.MessageFolders = action.payload;
        draft.error = "";
        break;
      case FETCH_MESSAGEFOLDERS_FAILURE:
        
        draft.error = action.payload;
        break;
      case SET_SELECTED_FOLDER_ID:
        draft.selectedFolderId = action.payload;
        break;
      case DELETE_MESSAGEFOLDER:
        key = draft.MessageFolders.findIndex((F) => F.id === action.payload.id);
        draft = { MessageFolders: draft.MessageFolders.splice(key, 1) };
        break;
      case ADD_MESSAGEFOLDER:
        key = draft.MessageFolders.findIndex(
          (F) =>
            F.userId === action.payload.userId &&
            F.folderName === action.payload.folderName &&
            F.folderLevel === action.payload.folderLevel
        );
        if(key === -1){
            draft.MessageFolders = [...draft.MessageFolders, action.payload];
        }
        break;
      case RENAME_MESSAGEFOLDER:
        key = draft.MessageFolders.findIndex((F) => F.id === action.payload.folderId);
        draft.MessageFolders[key].folderName = action.payload.folderName
        break
      default:
        return state;
    }
  });

export default messageFoldersReducer;
