import {
  FETCH_MONTH_DATA_SUCCESS,
  FETCH_MONTH_STATIC_DATA_SUCCESS,
  FETCH_MONTH_USER_DATA_SUCCESS,
  FETCH_MONTH_SUM_DATA_SUCCESS,
  SET_DAY_INFO,
} from "actions/types";

import { produce } from "immer";

const initialState = {
  MonthStatic: [],
  MonthSums: [],
  MonthUsers: [],
  dayInfo: {}
}

const monthReducer = (state = initialState, action) =>
  produce(state, draft => {
    const PL = action.payload
    switch (action.type) {
      case FETCH_MONTH_STATIC_DATA_SUCCESS:
        draft.MonthStatic = PL
        break    
      case FETCH_MONTH_SUM_DATA_SUCCESS:
        draft.MonthSums = PL
        break    
      case FETCH_MONTH_USER_DATA_SUCCESS:
        draft.MonthUsers = PL
        break
      case FETCH_MONTH_DATA_SUCCESS:
        draft.MonthStatic = PL.monthStatic;
        draft.MonthSums = PL.sums;
        draft.MonthUsers = PL.offdays;
        break;
      case SET_DAY_INFO:
        draft.dayInfo = PL
        break
      default: return state      
    }
  })

export default monthReducer