import {
  FETCH_REMINDERS_SUCCESS,
  ADD_REMINDER,
  UPDATE_REMINDER,
  DELETE_REMINDER,
  REMINDERS_FAILURE,
  SET_SELECTED_REMINDER,
  SET_DISPLAY_REMINDER,
  SET_REMINDER_ALERT,
  SET_REMINDER_ALERT_TIME,
  OPEN_REMINDER_DIALOG,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Reminders: [],
  error: "",
  displayReminders: [],
  selectedReminder: -2,
  showReminderDate: null,
};

const remindersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_REMINDERS_SUCCESS:
        draft.Reminders = action.payload;
        draft.selectedReminder = -2;

        draft.error = "";
        break;
      case ADD_REMINDER:
        draft.Reminders = [...draft.Reminders, action.payload];
        draft.selectedReminder = -2;
        break;
      case UPDATE_REMINDER:
        key = draft.Reminders.findIndex((R) => parseInt(R.id) === parseInt(action.payload.id));
        draft.Reminders[key] = action.payload;
        draft.selectedReminder = -2;
        break;
      case DELETE_REMINDER:
        key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
        draft = { Reminders: draft.Reminders.splice(key, 1) };
        draft.selectedReminder = -2;
        break;
      case REMINDERS_FAILURE:
        draft.error = action.payload;
        break;
      case SET_SELECTED_REMINDER:
        draft.selectedReminder = action.payload;
        break;
      case SET_DISPLAY_REMINDER:
        draft.displayReminders = action.payload.reminders;
        break;
      case SET_REMINDER_ALERT:
        key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
        draft.Reminders[key].alertSent = true;
        break;
      case SET_REMINDER_ALERT_TIME:
        key = draft.Reminders.findIndex((R) => R.id === action.payload.id);
        draft.Reminders[key] = { ...draft.Reminders[key], ...action.payload };
        break;
      case OPEN_REMINDER_DIALOG:
        draft.showReminderDate = action.payload;
        break;
      default:
        return state;
    }
  });

export default remindersReducer;
