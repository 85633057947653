import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import store from "../redux/store";
import { hasPermission } from "Components/Utilities";

import { getGlobalSetting } from "Components/Utilities";

import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SomuraTooltip from "Components/SomuraTooltip";
import { Box } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import OffDaysIcon from "@mui/icons-material/PublicTwoTone";
import DevicesIcon from "@mui/icons-material/Devices";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const mapStateToProps = (state) => {
  const loggedUser = state.loggeduser.LoggedUser;
  return {
    loginState: state.loggeduser.LoginState,
    loggedUser,
  };
};

const SettingsMenu = ({ loggedUser, ...props }) => {
  const { t } = useTranslation();

  const useTimeTrackModule =
    parseInt(getGlobalSetting("system", "useTimeTrackModule")) === 1;

  const [anchorEl, setAnchorEl] = useState(null);

  const openSettings = Boolean(anchorEl);

  const handleSettingsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (pageName) => {
    localStorage.setItem("lastPage", pageName);
    store.dispatch({
      type: "DISABLE_LOCATION_SELECTION",
      payload: false,
    });
    store.dispatch({ type: "SET_EDIT_MODE", payload: false });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <SomuraTooltip title={t("System settings")} placement="left">
        <IconButton
          sx={{
            width: "4rem",
          }}
          edge="end"
          aria-label="settings"
          aria-controls="settingsmenu"
          aria-haspopup="true"
          onClick={handleSettingsMenu}
          color="inherit"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </SomuraTooltip>
      <Menu
        sx={{
          marginTop: "-1rem",
        }}
        id="settingsmenu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSettings}
        onClose={() => handleClose("")}
      >
        <MenuItem
          disabled={true}
          sx={{
            fontSize: "1.2rem",
            color: "text.adminMenuTitle",
          }}
        >
          {t("Settings")}
        </MenuItem>
        {hasPermission("seeCompanyData") && (
          <MenuItem
            name="CompanySettings"
            component={NavLink}
            onClick={() => handleClose("CompanySettings")}
            to={"/CompanySettings"}
          >
            <ListItemIcon>
              <BusinessTwoToneIcon />
            </ListItemIcon>
            {t("Company")}
          </MenuItem>
        )}
        {hasPermission("seeAddressData") && (
          <MenuItem
            name="Addresses"
            component={NavLink}
            onClick={() => handleClose("Addresses")}
            to={"/Addresses"}
          >
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            {t("Addresses")}
          </MenuItem>
        )}
        {hasPermission("seeCalendarData") && (
          <MenuItem
            name="CalendarSettings"
            component={NavLink}
            onClick={() => handleClose("CalendarSettings")}
            to={"/CalendarSettings"}
          >
            <ListItemIcon>
              <CalendarTodayTwoToneIcon />
            </ListItemIcon>
            {t("Calendar")}
          </MenuItem>
        )}
        {hasPermission("seeAbsenceTypes") && (
          <MenuItem
            name="OffDayTypes"
            component={NavLink}
            onClick={() => handleClose("OffDayTypes")}
            to={"/OffDayTypes"}
          >
            <ListItemIcon>
              <OffDaysIcon />
            </ListItemIcon>
            {t("Absence types")}
          </MenuItem>
        )}
        {hasPermission("seeDocuments") && (
          <MenuItem
            name="Documents"
            component={NavLink}
            onClick={() => handleClose("Documents")}
            to={"/Documents"}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            {t("Documents")}
          </MenuItem>
        )}

        {hasPermission("seeArticleData") && (
          <MenuItem
            name="ArticleSettings"
            component={NavLink}
            onClick={() => handleClose("ArticleSettings")}
            to={"/ArticleSettings"}
          >
            <ListItemIcon>
              <DevicesIcon />
            </ListItemIcon>
            {t("Articles")}
          </MenuItem>
        )}
        {hasPermission("seeTimeTracking") && useTimeTrackModule && (
          <MenuItem
            name="TimeTrackingSettings"
            component={NavLink}
            onClick={() => handleClose("TimeTrackingSettings")}
            to={"/TimeTrackingSettings"}
          >
            <ListItemIcon>
              <TimerOutlinedIcon />
            </ListItemIcon>
            {t("Time tracking")}
          </MenuItem>
        )}
        {hasPermission("seeRollsRights") && (
          <MenuItem
            name="Security"
            component={NavLink}
            onClick={() => handleClose("Security")}
            to={"/Security"}
          >
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon />
            </ListItemIcon>
            {t("Rolls & Rights")}
          </MenuItem>
        )}
        {hasPermission("seeSystemSettings") && (
          <MenuItem
            name="GlobalSettings"
            component={NavLink}
            onClick={() => handleClose("GlobalSettings")}
            to={"/GlobalSettings"}
          >
            <ListItemIcon>
              <Box
                component="img"
                sx={{
                  width: "24px",
                  height: "20px",
                  display: "flex",
                }}
                alt="Logo"
                src="images/Somura-icon_2tone-light_512.svg"
              />
            </ListItemIcon>
            Somura
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default connect(mapStateToProps)(SettingsMenu);
