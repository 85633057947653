import {
  READ_EXIT_REASONS,
  CREATE_EXIT_REASON,
  UPDATE_EXIT_REASON,
  DELETE_EXIT_REASON,
  SET_SELECTED_EXIT_REASON,
} from "actions/types";

import { produce } from "immer";

const initialState = {
  ExitReasons: [],
  exitReasonId: -1,
};

const exitReasonsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const PL = action.payload;
    let key = 0;
    switch (action.type) {
      case READ_EXIT_REASONS:
        draft.ExitReasons = PL;
        break;
      case CREATE_EXIT_REASON:
        draft.ExitReasons = [...draft.ExitReasons, PL];
        draft.exitReasonId = -1;
        break;
      case UPDATE_EXIT_REASON:
        key = draft.ExitReasons.findIndex(ER => parseInt(ER.id) === parseInt(PL.id))
        draft.ExitReasons[key] = PL
        draft.exitReasonId = -1;
        break;
      case DELETE_EXIT_REASON:
        key = draft.ExitReasons.findIndex(
          (ER) => parseInt(ER.id) === parseInt(PL.id)
        );
        draft = { ExitReasons: draft.ExitReasons.splice(key, 1) };
        draft.exitReasonId = -1;
        break;        
      case SET_SELECTED_EXIT_REASON:
        draft.exitReasonId = PL;
        break;
      default:
        return state;
    }
  });

export default exitReasonsReducer;        