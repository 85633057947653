import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SomuraTooltip from "Components/SomuraTooltip";
import theme from "../theme/Theme";

const SomuraSelectField = (props) => {
  const items = props.options?.map((O, index) => (
    <MenuItem
      sx={{ height: "100%", padding: "2px 6px" }}
      key={index}
      value={O.value}
      disabled={O.disabled}
    >
      {O.label}
    </MenuItem>
  ));
  return (
    <SomuraTooltip title={props.tooltiptitle} placement="top" arrow={true}>
      <Box sx={{ border: "none", width: "100%", ...props.sx }}>
        <FormControl
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "4px",
            border: "none",
            boxShadow: props.required && !props.disabled
              ? `inset 0 0 5px ${theme.palette.secondary.main}`
              : "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
            ...props.sx,
          }}
          size="small"
          fullWidth
        >
          <InputLabel sx={{ textTransform: "uppercase" }}>
            {props.label ? props.label + (props.required ? " *" : "") : ""}
          </InputLabel>
          <Select
            sx={{
              border: "none",
              borderRadius: "4px",
            }}
            name={props.name}
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            disabled={props.disabled}
            labelId="demo-simple-select-label"
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "#3E3E3E",
                  maxHeight: props.maxheight || "10rem",
                },
              },
            }}
          >
            {items}
          </Select>
        </FormControl>
      </Box>
    </SomuraTooltip>
  );
};

export default SomuraSelectField;
