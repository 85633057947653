import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const DeleteButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      startIcon={<DeleteIcon />}
      color="delete"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {t("Delete")}
    </Button>
  );
};

export default DeleteButton
