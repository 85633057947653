import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

const SaveButton = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      type="submit"
      variant="contained"
      startIcon={<SaveIcon />}
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {t("Save")}
    </Button>
  );
};

export default SaveButton
