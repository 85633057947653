import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { hasPermission } from "Components/Utilities";
import { setLocationId } from "../redux/index";
import SomuraSelectField from "./SomuraSelectField";

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.selections.selectedLocationId,
    locations: state.company.Locations,
    disabled: state.general.locationSelectionDisabled,
    allLocationsDisabled: state.general.locationSelectionAllDisabled
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocationId: (values) => dispatch(setLocationId(values)),
  };
};

const LocationSelection = ({
  selectedLocation,
  locations,
  disabled,
  allLocationsDisabled,
  setLocationId,
}) => {
  const { t } = useTranslation();

  var items = locations?.map((L, index) => ({
    label: L.locationName,
    value: L.id,
  }));
  items.unshift({
    label: t("All company locations"),
    value: 0,
    disabled: allLocationsDisabled,
  });

  const handleLocationChange = (event) => {
    const selectedValue = event.target.value;
    setLocationId(selectedValue);
  };

  return (
    <SomuraSelectField
      sx={{
        width: "16rem",
        height: "2.5rem",
        border: "none !important",
        borderRadius: "4px",
        boxShadow: "inset 1px 1px 3px 1px rgba(0, 0, 0, 0.8)",
      }}
      options={items}
      defaultValue={0}
      value={
        selectedLocation === undefined ||
        selectedLocation === null ||
        items.length === 1
          ? ""
          : selectedLocation
      }
      onChange={handleLocationChange}
      size="small"
      disabled={!hasPermission("seeAllCompanyLocations") || disabled}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelection);
