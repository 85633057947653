import {
  SET_FIRST_SECOND,
  SET_EDIT_MODE,
  SET_DRAWER_OPEN,
  SET_DISPLAY_YEAR,
  SET_DISPLAY_ABSENCE_TYPE,
  SET_SYSTEM_LANGUAGE,
  DISABLE_LOCATION_SELECTION,
  DISABLE_LOCATIONS_ALL_SELECTION,
  SHOW_MESSAGE,
  DATA_FAILURE,
  DATA_SUCCESS,
  DATA_RESET,
  SET_PROGRESS_VALUE,
  SHOW_CONTEXT_MENU,
  ACTION_SUCCESS,
  FETCH_CURRENT_STATS,
  FETCH_CURRENT_VERSIONS,
  FETCH_SOMURA_START
} from "actions/types";

import { produce } from "immer";
import { DateTime } from "luxon";

const initialState = {
  FirstSecond: "",
  locationSelectionDisabled: false,
  locationSelectionAllDisabled: false,
  // This is not supposed to be in here. TODO: Move this into components' states
  EditMode: false,
  drawerOpen: true,
  DisplayYear: DateTime.now().year,
  DisplayAbsenceType: "-1",
  // This is not supposed to be in here. TODO: Move this into components' states
  showMessage: null,
  dataLoading: 0,
  error: "",
  dataError: false,
  dataSuccess: false,
  actionSuccess: false,
  // This is not supposed to be in here. TODO: Move this into components' states
  progressValue: 0,
  language: "de-DE",
  languageShort: "DEU",
  // This is not supposed to be in here. TODO: Move this into components' states
  showContextMenu: null,
  Stats: {},
  Versions: [],
  somuraStartYear: 0
};

const generalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    // eslint-disable-next-line
    switch (action.type) {
      case SET_FIRST_SECOND:
        draft.FirstSecond = action.payload.time;
        break;
      case DISABLE_LOCATION_SELECTION:
        draft.locationSelectionDisabled = action.payload;
        break;
      case DISABLE_LOCATIONS_ALL_SELECTION:
        draft.locationSelectionAllDisabled = action.payload
        break
      case DATA_SUCCESS:
        draft.dataError = false;
        draft.dataSuccess = true;
        if (draft.dataLoading > 0) {
          draft.dataLoading--;
        }
        draft.error = "";
        break;
      case DATA_FAILURE:
        draft.dataError = true;
        draft.dataSuccess = false;
        draft.error = action.payload;
        break;
      case DATA_RESET:
        draft.dataError = false;
        draft.dataSuccess = false;
        draft.actionSuccess = false;
        break;
      case SET_EDIT_MODE:
        draft.EditMode = action.payload;
        break;
      case SET_DRAWER_OPEN:
        draft.drawerOpen = action.payload;
        break;
      case SET_DISPLAY_YEAR:
        draft.DisplayYear = action.payload;
        break;
      case SET_DISPLAY_ABSENCE_TYPE:
        draft.DisplayAbsenceType = action.payload;
        break;
      case SET_SYSTEM_LANGUAGE:
        draft.language = action.payload.language;
        draft.languageShort = action.payload.languageShort;
        break;
      case SHOW_MESSAGE:
        draft.showMessage = action.payload;
        break;
      case SET_PROGRESS_VALUE:
        draft.progressValue = action.payload;
        break;
      case SHOW_CONTEXT_MENU:
        draft.showContextMenu = action.payload;
        break;
      case ACTION_SUCCESS:
        draft.actionSuccess = true;
        break;
      case FETCH_CURRENT_STATS:
        draft.Stats = action.payload;
        break;
      case FETCH_CURRENT_VERSIONS:
        draft.Versions = action.payload
        break;
      case FETCH_SOMURA_START:
        draft.somuraStartYear = action.payload.firstYear
        break;
      default:
        return state;
    }
  });

export default generalReducer;
