import {
  FETCH_ROLLS_SUCCESS,
  CREATE_ROLL_SUCCESS,
  UPDATE_ROLL_SUCCESS,
  DELETE_ROLL_SUCCESS,
  COPY_RIGHTS_SUCCESS,
  SET_SELECTED_ROLL_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Rolls: [],
  selectedRollId: -1,
  error: "",
};

const securityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_ROLLS_SUCCESS:
        draft.Rolls = action.payload;
        draft.error = "";
        break;
      case CREATE_ROLL_SUCCESS:
        draft.Rolls = [...draft.Rolls, {numRolls: 0, ...action.payload}];
        draft.error = "";
        break;
      case UPDATE_ROLL_SUCCESS:
        key = draft.Rolls.findIndex((R) => R.id === action.payload.id);
        draft.Rolls[key] = action.payload;
        draft.error = "";
        draft.selectedRollId = -1;
        break;
      case DELETE_ROLL_SUCCESS:
        key = draft.Rolls.findIndex((R) => R.id === action.payload.id);
        draft.Rolls.splice(key, 1);
        draft.error = "";
        draft.selectedRollId = -1;
        break;
      case COPY_RIGHTS_SUCCESS:
        key = draft.Rolls.findIndex((R) => parseInt(R.id) === parseInt(action.payload.targetId));
        draft.Rolls[key].rights = draft.Rolls.find(R => parseInt(R.id) === parseInt(action.payload.sourceId)).rights
        break
      case SET_SELECTED_ROLL_ID:
        draft.selectedRollId = action.payload
        break
      default:
        return state;
    }
  });

export default securityReducer;
