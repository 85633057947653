import {
  FETCH_WEEKLY_TIME_PLANS_SUCCESS,
  CREATE_WEEKLY_TIME_PLAN,
  UPDATE_WEEKLY_TIME_PLAN,
  DELETE_WEEKLY_TIME_PLAN,
  SET_WEEKLY_TIME_PLAN_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  WeeklyTimePlans: [],
  selectedWeeklyTimePlanId: -1,
  error: "",
};

const weeklyTimePlansReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_WEEKLY_TIME_PLANS_SUCCESS:
        draft.WeeklyTimePlans = action.payload;
        
        draft.error = "";
        break;
      case CREATE_WEEKLY_TIME_PLAN:
        draft.WeeklyTimePlans = [...draft.WeeklyTimePlans, action.payload];
        
        draft.error = "";
        break;
      case UPDATE_WEEKLY_TIME_PLAN:
        key = draft.WeeklyTimePlans.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.WeeklyTimePlans[key] = action.payload;
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case DELETE_WEEKLY_TIME_PLAN:
        key = draft.WeeklyTimePlans.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.WeeklyTimePlans.splice(key, 1);
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case SET_WEEKLY_TIME_PLAN_ID:
        draft.selectedWeeklyTimePlanId = action.payload;
        break;
      default:
        return state;
    }
  });

export default weeklyTimePlansReducer;
