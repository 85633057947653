import {
  FETCH_TIMES_SUCCESS,
  FETCH_LAST_TIMES_SUCCESS,
  FETCH_USERTIMES_SUCCESS,
  FETCH_USERTIMES_CUM_SUCCESS,
  FETCH_TIMES_FAULTY_SUCCESS,
  FETCH_USER_MONTH_TIMES_SUCCESS,
  FETCH_ALIVE_TIME_SUCCESS,
  FETCH_USER_TIME_STATE,
  SET_WEB_TIME,
  SET_TIME,
  END_TIME,
  SET_SELECTED_DATE,
  SET_SELECTED_TIME,
  SET_SELECTED_TIME_FORM_ID,
  SET_START_TIME,
  SET_END_TIME,
  OPEN_START_TIME,
  OPEN_END_TIME,
  UPDATE_TIME,
  DELETE_TIME,
  ADD_TIME,
  TERMINATE_DAY_SUCCESS,
  OPEN_TERMINATION,
  FETCH_MONTH_TERMINATED,
  FETCH_NOT_TERMINATED,
  FETCH_TIME_SUMS,
  FETCH_TIME_STATE,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Times: [],
  UserTimes: [],
  UserTimesCum: [],
  MonthTimes: [],
  MyState: [],
  Sums: [],
  selectedDate: "",
  selectedTimeId: -1,
  selectedTimeFormId: -1,
  startTime: null,
  endTime: null,
  aliveTime: null,
  openStartTime: false,
  openEndTime: false,
  terminationRecords: 0,
  openTermination: false,
  monthTerminated: false,
  notTerminated: [],
  returnedState: "",
};

const timesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_TIMES_SUCCESS:
        draft.Times = action.payload;
        draft.error = "";
        break;
      case FETCH_LAST_TIMES_SUCCESS:
        draft.Times = action.payload;
        draft.error = "";
        break;
      case FETCH_USERTIMES_SUCCESS:
        draft.UserTimes = action.payload;
        draft.error = "";
        break;
      case FETCH_USERTIMES_CUM_SUCCESS:
        draft.UserTimesCum = action.payload;
        draft.error = "";
        break;
      case FETCH_TIMES_FAULTY_SUCCESS:
        draft.UserTimesCum = action.payload;
        draft.error = "";
        break;
      case FETCH_USER_MONTH_TIMES_SUCCESS:
        draft.MonthTimes = action.payload;
        draft.error = "";
        break;
      case FETCH_ALIVE_TIME_SUCCESS:
        draft.aliveTime = action.payload;
        break;
      case FETCH_USER_TIME_STATE:
        draft.MyState = action.payload;
        break;
      case SET_WEB_TIME:
        draft.selectedTimeId = action.payload.id;
        draft.MyState.workMinutes = action.payload.workMinutes;
        draft.MyState.weekMinutes = (
          parseInt(draft.MyState.weekMinutes) +
          parseInt(action.payload.workMinutes)
        ).toString();
        draft.MyState.monthMinutes = (
          parseInt(draft.MyState.monthMinutes) +
          parseInt(action.payload.workMinutes)
        ).toString();
        draft.MyState.breakMinutes = action.payload.breakMinutes;
        if (action.payload.returnCode === "Start break") {
          draft.MyState.startMinutes = action.payload.time;
          draft.MyState.worktimeRunning = false;
          draft.MyState.breaktimeRunning = true;
        } else if (action.payload.returnCode === "Enter") {
          draft.MyState.startMinutes = action.payload.time;
          draft.MyState.worktimeRunning = true;
          draft.MyState.breaktimeRunning = false;
        } else {
          draft.MyState.endMinutes = action.payload.time;
          draft.MyState.worktimeRunning = false;
          draft.MyState.breaktimeRunning = false;
        }
        break;
      case SET_TIME:
        draft.Times = [...draft.Times, { ...action.payload, endTime: null }];
        draft.selectedTimeId = action.payload.id;
        break;
      case END_TIME:
        key = draft.Times.findIndex(
          (T) => parseInt(T.id) === parseInt(action.payload.id)
        );
        draft.Times[key] = { ...draft.Times[key], ...action.payload };
        break;
      case UPDATE_TIME:
        key = draft.UserTimes.findIndex(
          (T) => parseInt(T.id) === parseInt(action.payload.id)
        );
        draft.UserTimes[key] = { ...draft.UserTimes[key], ...action.payload };
        key = draft.Times.findIndex(
          (T) => parseInt(T.id) === parseInt(action.payload.id)
        );
        draft.Times[key] = { ...draft.Times[key], ...action.payload };
        draft.selectedTimeId = -1;
        break;
      case DELETE_TIME:
        key = draft.UserTimes.findIndex(
          (T) => parseInt(T.id) === parseInt(action.payload.id)
        );
        draft = { Times: draft.UserTimes.splice(key, 1) };
        key = draft.Times.findIndex(
          (T) => parseInt(T.id) === parseInt(action.payload.id)
        );
        draft = { Times: draft.Times.splice(key, 1) };
        draft.selectedTimeId = -1;
        break;
      case ADD_TIME:
        draft.UserTimes = [...draft.UserTimes, { ...action.payload }];
        draft.Times = [...draft.Times, { ...action.payload }];
        draft.selectedTimeId = action.payload.id;
        break;
      case SET_SELECTED_DATE:
        draft.selectedDate = action.payload;
        break;
      case SET_SELECTED_TIME:
        draft.selectedTimeId = action.payload;
        break;
      case SET_SELECTED_TIME_FORM_ID:
        draft.selectedTimeFormId = action.payload;
        break;
      case SET_START_TIME:
        draft.startTime = action.payload;
        break;
      case SET_END_TIME:
        draft.endTime = action.payload;
        break;
      case OPEN_START_TIME:
        draft.openStartTime = action.payload;
        break;
      case OPEN_END_TIME:
        draft.openEndTime = action.payload;
        break;
      case TERMINATE_DAY_SUCCESS:
        draft.terminationRecords = action.payload.num_inserted;
        break;
      case OPEN_TERMINATION:
        draft.openTermination = action.payload;
        break;
      case FETCH_MONTH_TERMINATED:
        draft.monthTerminated = action.payload;
        break;
      case FETCH_NOT_TERMINATED:
        draft.notTerminated = action.payload;
        break;
      case FETCH_TIME_SUMS:
        draft.Sums = action.payload;
        break;
      case FETCH_TIME_STATE:
        draft.returnedState = action.payload.timeState;
        break;
      default:
        return draft;
    }
  });

export default timesReducer;
