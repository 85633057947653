import {
  FETCH_DOCS_TITLES_SUCCESS,
  FETCH_DOC_SUCCESS,
  CREATE_DOC,
  UPDATE_DOC,
  DELETE_DOC,
  SET_DOC_ID,
} from "actions/types";

import {
  docsReadTitles,
  docRead,
  docCreate,
  docUpdate,
  docDelete} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";

const fetchDocsTitlesSuccess = (values) => {
  return {
    type: FETCH_DOCS_TITLES_SUCCESS,
    payload: values,
  };
};

export const fetchDocsTitles = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(docsReadTitles(values));
      await dispatch(fetchDocsTitlesSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchDocSuccess = (values) => {
  return {
    type: FETCH_DOC_SUCCESS,
    payload: values,
  };
};

export const fetchDoc = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(docRead(values));
      await dispatch(fetchDocSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createDocData = (values) => {
  return {
    type: CREATE_DOC,
    payload: values,
  };
};

export const createDoc = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(docCreate(values));
      await dispatch(createDocData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDocData = (values) => {
  return {
    type: UPDATE_DOC,
    payload: values,
  };
};

export const updateDoc = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(docUpdate(values));
      await dispatch(updateDocData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};
const deleteDocData = (values) => {
  return {
    type: DELETE_DOC,
    payload: values,
  };
};

export const deleteDoc = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(docDelete(values));
      await dispatch(deleteDocData({ ...values, id: JSONData }));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};


export const setDocId = (values) => {
  return {
    type: SET_DOC_ID,
    payload: values,
  };
};
