import {
  CHECK_MAIL,
  CHECK_CREDENTIALS,
  SET_LOGIN_STATE,
  SET_LOGIN_NAME,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  SET_LOGGED_USER,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  LoggedUser: {},
  // I have researched the usage of this and i am shocked.
  // This needs to refactored or removed. Or properly merged with auth.isAuthenticated.
  LoginState: "0",
  LoginName: "",
  error: "",
};

const loggedUserReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHECK_MAIL:
        draft.LoginState = action.payload.loginState;
        draft.LoginName = action.payload.loginName;
        break;
      case CHECK_CREDENTIALS:
        draft.LoginState = action.payload.loginState;
        draft.LoginName = action.payload.loginName;
        break;
      case SET_LOGIN_STATE:
        draft.LoginState = action.payload;
        break;
      case SET_LOGIN_NAME:
        draft.LoginName = action.payload;
        break;
      case LOGIN_SUCCESS:
        draft.LoggedUser = action.payload
        draft.LoginState = action.payload.loginState;
        break;
      case LOGIN_FAILURE:
        draft.LoggedUser = {}
        draft.LoginState = "-1"
        draft.error = action.payload;
        break;
      case LOGOUT_SUCCESS:
        draft.LoggedUser = {};
        break;
      case SET_LOGGED_USER:
        draft.LoggedUser = action.payload;
        break;
      default:
        return draft;
    }
  });

export default loggedUserReducer;
