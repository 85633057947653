import {
  FETCH_PROJECTS_SUCCESS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SET_PROJECT_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Projects: [],
  selectedProjectId: -1,
  error: "",
};

const projectsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_PROJECTS_SUCCESS:
        draft.Projects = action.payload;
        draft.error = "";
        break;
      case CREATE_PROJECT:
        draft.Projects = [...draft.Projects, action.payload];
        
        draft.error = "";
        break;
      case UPDATE_PROJECT:
        key = draft.Projects.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.Projects[key] = action.payload;
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case DELETE_PROJECT:
        key = draft.Projects.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.Projects.splice(key, 1);
        
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case SET_PROJECT_ID:
        draft.selectedProjectId = action.payload;
        break;
      default:
        return state;
    }
  });

export default projectsReducer;
