import {
  SET_SELECTED_WORKDAYS, 
  SET_SELECTED_CALENDARDAYS,
  SET_SELECTED_DAYS,
  SET_SELECTED_IDS,
  // SET_DISPLAY_USER,
  FETCH_STATS_SUCCESS
} from 'actions/types'
import { produce } from "immer";

const initialState = {
  selectedWorkDays: 0,
  selectedCalendarDays: 0,
  selectedDays: [],
  startId: 0,
  endId: 0,
  days: [],
  displayUserId: 0,
  userStats: []
}

const yearCalendarReducer = (state = initialState, action) => 
produce(state, draft => {
  switch (action.type) {
    case SET_SELECTED_WORKDAYS:
      draft.selectedWorkDays = action.payload
      break
    case SET_SELECTED_CALENDARDAYS:
      draft.selectedCalendarDays = action.payload
      break
    case SET_SELECTED_DAYS:
      draft.selectedDays = action.payload
      break
    case SET_SELECTED_IDS:
      draft.startId = action.payload.startId
      draft.endId = action.payload.endId
      break
    // case SET_DISPLAY_USER:
    //   draft.displayUserId = action.payload
    //   break
    case FETCH_STATS_SUCCESS:
      draft.userStats = action.payload
      break
    default: return state
  }
})
  
    
export default yearCalendarReducer   