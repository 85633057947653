import {
  FETCH_LOG_SUCCESS,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Log: [],
  error: "",
};

const logReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_LOG_SUCCESS:
        draft.Log = action.payload;
        
        draft.error = "";
        break;
      default:
        return state;
    }
  });

export default logReducer;
