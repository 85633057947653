import {
  FETCH_DOCS_TITLES_SUCCESS,
  FETCH_DOC_SUCCESS,
  CREATE_DOC,
  UPDATE_DOC,
  DELETE_DOC,
  SET_DOC_ID,
  SET_DOC_SETTINGS,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  Documents: [],
  Document: {},
  selectedDocumentId: -1,
  error: "",
};

const documentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_DOCS_TITLES_SUCCESS:
        draft.Documents = action.payload;
        draft.error = "";
        break;
      case FETCH_DOC_SUCCESS:
        draft.Document = action.payload;
        draft.error = "";
        break;
      case CREATE_DOC:
        draft.Documents = [...draft.Documents, action.payload];
        draft.error = "";
        break;
      case UPDATE_DOC:
        key = draft.Documents.findIndex(
          (DOC) => DOC.id === action.payload.id
        );
        draft.Documents[key] = action.payload;
        
        draft.error = "";
        draft.selectedDocumentId = -1;
        break;
      case DELETE_DOC:
        key = draft.Documents.findIndex(
          (DOC) => DOC.id === action.payload.id
        );
        draft.Documents.splice(key, 1);
        
        draft.error = "";
        draft.selectedDocumentId = -1;
        break;
      case SET_DOC_ID:
        draft.selectedDocumentId = action.payload;
        break;
      case SET_DOC_SETTINGS:
        key = draft.Documents.findIndex((DOC) => DOC.id === draft.selectedDocumentId);
        draft.Documents[key].settings = action.payload;
        draft.Document.settings = action.payload
        break
      default:
        return state;
    }
  });

export default documentsReducer;
