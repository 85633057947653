import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATED,
  AUTH_LOGON,
  AUTH_LOGON_ERROR,
  AUTH_LOGOUT,
  AUTH_ERROR,
  LOGIN_SUCCESS
} from "../actions/types";

const defaultState = {
  error: false,
  isAuthenticated: false,
  authenticating: true,
};

function authReducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return {
        ...state,
        authenticating: action.authenticating,
        error: false,
      };

    case AUTH_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authenticating: false,
        error: false,
      };      

    case AUTH_LOGON:
      return {
        ...state,
        impersonation: action.logon.impersonation,
        error: false,
      };

    case AUTH_LOGON_ERROR:
      return {
        ...state,
        error: true,
        isAuthenticated: false,
      };

    case AUTH_LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        error: false,
      };
    }

    case AUTH_ERROR: {
      return {
        ...state,
        authenticated: false,
        error: true,
      };
    }

    default:
      return state;
  }
}

export default authReducer;
