import {
  FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS,
  CREATE_EXCLUDED_RECURRING_ABSENCE,
  DELETE_EXCLUDED_RECURRING_ABSENCES,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  ExcludedRecurringAbsences: [],
  error: "",
};

const excludedRecurringAbsencesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_EXCLUDED_RECURRING_ABSENCES_SUCCESS:
        draft.ExcludedRecurringAbsences = action.payload;
        
        draft.error = "";
        break;
      case CREATE_EXCLUDED_RECURRING_ABSENCE:
        draft.ExcludedRecurringAbsences = [...draft.ExcludedRecurringAbsences, action.payload];
        
        draft.error = "";
        break;
      case DELETE_EXCLUDED_RECURRING_ABSENCES:
        key = draft.RecurringAbsences.filter(
          (RA) => RA.recurringAbsenceId === action.payload.recurringAbsenceId
        );
        draft.RecurringAbsences = key;
        
        draft.error = "";
        break;
      default:
        return state;
    }
  });

export default excludedRecurringAbsencesReducer;
