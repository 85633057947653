import {
  FETCH_OFFDAYTYPES_SUCCESS,
  OFFDAYTYPES_FAILURE,
  UPDATE_OFFDAYTYPE,
  ADD_OFFDAYTYPE,
  DELETE_OFFDAYTYPE,
  SET_OFFDAYTYPE_ID,
  UPDATE_OFFDAYTEXT,
  ADD_OFFDAYTEXT,
  DELETE_OFFDAYTEXT,
} from "actions/types";

import { produce } from "immer";

const initialState = {
  OffDayTypes: [],
  error: "",
  offDayTypeId: -1,
};

const offDayTypesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const PL = action.payload;
    let key = 0;
    let textKey = 0;
    switch (action.type) {
      case FETCH_OFFDAYTYPES_SUCCESS:
        draft.OffDayTypes = action.payload;
        
        draft.error = "";
        break;
      case OFFDAYTYPES_FAILURE:
        
        draft.error = "";
        break;
      case SET_OFFDAYTYPE_ID:
        draft.offDayTypeId = action.payload;
        break;
      case UPDATE_OFFDAYTYPE:
        key = draft.OffDayTypes.findIndex((OT) => OT.id === PL.id);
        draft.OffDayTypes[key] = PL;
        draft.offDayTypeId = -1;
        break;
      case ADD_OFFDAYTYPE:
        draft.OffDayTypes = [...draft.OffDayTypes, PL];
        draft.offDayTypeId = -1;
        break;
      case DELETE_OFFDAYTYPE:
        key = draft.OffDayTypes.findIndex((OT) => OT.id === PL.id);
        draft = { OffDayTypes: draft.OffDayTypes.splice(key, 1) };
        draft.offDayTypeId = -1;
        break;
      case UPDATE_OFFDAYTEXT:
        key = draft.OffDayTypes.findIndex((OT) => OT.id === PL.offDayTypeId);
        textKey = draft.OffDayTypes[key].additionalTexts.findIndex(
          (AT) => AT.id === PL.id
        );
        draft.OffDayTypes[key].additionalTexts[textKey].text = PL.text;
        draft.OffDayTypes[key].additionalTexts[textKey].selectable =
          PL.selectable;
        break;
      case ADD_OFFDAYTEXT:
        key = draft.OffDayTypes.findIndex((OT) => OT.id === PL.offDayTypeId);
        draft.OffDayTypes[key].additionalTexts = [
          ...draft.OffDayTypes[key].additionalTexts,
          {...PL, numAssigned: 0},
        ];
        break;
      case DELETE_OFFDAYTEXT:
        key = draft.OffDayTypes.findIndex((OT) => OT.id === PL.offDayTypeId);
        textKey = draft.OffDayTypes[key].additionalTexts.findIndex(
          (AT) => AT.id === PL.id
        );
        const textsArr = draft.OffDayTypes[key].additionalTexts;
        draft.OffDayTypes[key] = {
          additionalTexts: textsArr.splice(textKey, 1),
          ...draft.OffDayTypes[key],
        };
        break;
      default:
        return state;
    }
  });

export default offDayTypesReducer;
