import React, { useEffect } from "react";
import store from "./redux/store";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import {
  initializeDatabase,
  fetchGlobalSettings,
  authAuthenticated,
} from "./redux/index";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { AppContext } from "./Components/context";
import { ErrorBoundary } from "react-error-boundary";
import MainMenu from "MainMenu/MainMenu";

const mapDispatchToProps = (dispatch) => {
  return {
    initializeDatabase: () => dispatch(initializeDatabase()),
    fetchGlobalSettings: (values) => dispatch(fetchGlobalSettings(values)),
    authenticateUser: (state) => dispatch(authAuthenticated(state)),
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const App = ({
  classes,
  isAuthenticated,
  authenticateUser,
  ...props
}) => {
  const routesProps = {
    isAuthenticated,
  };

  useEffect(() => {
    store.dispatch({
      type: "SET_FIRST_SECOND",
      payload: { time: DateTime.now().toFormat("HH:mm:ss") },
    });
    props.initializeDatabase();
    props.fetchGlobalSettings({locationId: localStorage.getItem('location')});
    authenticateUser(!!localStorage.getItem("jwt"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <Box sx={{ marginTop: "10rem", textAlign: "center" }}>
          Something went wrong
        </Box>
      }
    >
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flex: 1,
            backgroundColor: "background.default",
            fontWeight: 300,
            overflow: "hidden",
          }}
        >
          <AppContext.Provider value={{ isAuthenticated }}>
            {isAuthenticated && <Box />}
            <MainMenu>
              <Routes childProps={routesProps} />
            </MainMenu>
          </AppContext.Provider>
        </Box>
    </ErrorBoundary>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
