import React from "react";

import { Route, Redirect } from "react-router-dom";

import { querystring } from "./Utilities";

// eslint-disable-next-line react/prop-types
const UnauthenticatedRoute = ({ component: C, props: childProps, ...rest }) => {
  const redirect = querystring("redirect");
  return (
    <Route
      {...rest}
      render={(props) =>
        !childProps.isAuthenticated ? (
          <C {...props} {...childProps} />
        ) : (
          <Redirect
            to={redirect === "" || redirect === null ? "/" : redirect}
          />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;