import {
  FETCH_CHANGELOG_SUCCESS,
  CREATE_CHANGELOG_SUCCESS,
  UPDATE_CHANGELOG_SUCCESS,
  DELETE_CHANGELOG_SUCCESS,
  SET_CHANGELOG_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  ChangeLog: [],
  changeLogId: -1,
  error: "",
};

const securityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_CHANGELOG_SUCCESS:
        draft.ChangeLog = action.payload;
        draft.error = "";
        break;
      case CREATE_CHANGELOG_SUCCESS:
        draft.ChangeLog = [action.payload, ...draft.ChangeLog];
        draft.error = "";
        break;
      case UPDATE_CHANGELOG_SUCCESS:
        key = draft.ChangeLog.findIndex((CL) => CL.id === action.payload.id);
        draft.ChangeLog[key] = action.payload;
        draft.changeLogId = -1;
        draft.error = "";
        break;
      case DELETE_CHANGELOG_SUCCESS:
        key = draft.ChangeLog.findIndex((CL) => CL.id === action.payload.id);
        draft.ChangeLog.splice(key, 1);
        draft.changeLogId = -1;
        draft.error = "";
        break;
      case SET_CHANGELOG_ID:
        draft.changeLogId = action.payload;
        break;
      default:
        return state;
    }
  });

export default securityReducer;
