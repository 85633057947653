import {
  FETCH_RECURRING_ABSENCES_SUCCESS,
  CREATE_RECURRING_ABSENCE,
  UPDATE_RECURRING_ABSENCE,
  DELETE_RECURRING_ABSENCE,
  SET_RECURRING_ABSENCE_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  RecurringAbsences: [],
  selectedAbsenceId: -1,
  error: "",
};

const recurringAbsencesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let key = 0;
    switch (action.type) {
      case FETCH_RECURRING_ABSENCES_SUCCESS:
        draft.RecurringAbsences = action.payload;
        draft.error = "";
        break;
      case CREATE_RECURRING_ABSENCE:
        draft.RecurringAbsences = [...draft.RecurringAbsences, action.payload];
        draft.error = "";
        break;
      case UPDATE_RECURRING_ABSENCE:
        key = draft.RecurringAbsences.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.RecurringAbsences[key] = action.payload;
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case DELETE_RECURRING_ABSENCE:
        key = draft.RecurringAbsences.findIndex(
          (RA) => RA.id === action.payload.id
        );
        draft.RecurringAbsences.splice(key, 1);
        draft.error = "";
        draft.selectedAbsenceId = -1;
        break;
      case SET_RECURRING_ABSENCE_ID:
        draft.selectedAbsenceId = action.payload;
        break;
      default:
        return state;
    }
  });

export default recurringAbsencesReducer;
